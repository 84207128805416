@media (max-width: 767px) {
  .event-header-live-stats {
    padding: 0 15px 15px;
  }
}
.aside .event-header-live-stats,
.is-mobile .event-header-live-stats {
  padding: 0 15px 15px;
}
.event-header-live-stats__section:not(:last-child) {
  margin-bottom: 14px;
}
@media (max-width: 767px) {
  .event-header-live-stats__section:not(:last-child) {
    margin-bottom: 20px;
  }
}
.aside .event-header-live-stats__section:not(:last-child),
.is-mobile .event-header-live-stats__section:not(:last-child) {
  margin-bottom: 20px;
}
.event-stats-football-shots__label {
  font-family: "SF Pro", "SF Pro TH", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: #e9e9e9;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 4px;
}
.event-stats-football-shots__content {
  display: flex;
  border-radius: 12px;
  overflow: hidden;
}
.event-stats-football-shots__content > div {
  width: 50%;
  height: 80px;
  position: relative;
}
@media (max-width: 767px) {
  .event-stats-football-shots__content > div {
    height: 80px;
  }
}
.event-stats-football-shots__content > div:first-child {
  background-color: #4575d6;
  color: #4575d6;
}
.event-header .event-stats-football-shots__content > div {
  height: 54px;
}
.aside .event-stats-football-shots__content > div,
.is-mobile .event-stats-football-shots__content > div {
  height: 80px;
}
.event-stats-football-shots__content > div * {
  z-index: 5;
}
.event-stats-football-shots__content > div span {
  font-family: Montserrat, "Montserrat", sans-serif;
  font-weight: 700;
  font-style: italic;
}
.event-stats-football-shots__content > div span {
  font-size: 18px;
  background: #fff;
  border-radius: 100px;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .event-stats-football-shots__content > div span {
    font-size: 13px;
    height: 20px;
    width: 20px;
  }
}
.event-header .event-stats-football-shots__content > div span {
  font-size: 13px;
  height: 20px;
  width: 20px;
}
.aside .event-stats-football-shots__content > div span,
.is-mobile .event-stats-football-shots__content > div span {
  font-size: 13px;
  height: 20px;
  width: 20px;
}
.event-stats-football-shots__content > div:first-child span:first-child {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .event-stats-football-shots__content > div:first-child span:first-child {
    left: 50%;
    top: 20%;
  }
}
.event-header
  .event-stats-football-shots__content
  > div:first-child
  span:first-child {
  left: 15%;
  top: 52%;
}
.aside .event-stats-football-shots__content > div:first-child span:first-child,
.is-mobile
  .event-stats-football-shots__content
  > div:first-child
  span:first-child {
  left: 50%;
  top: 20%;
}
.event-header-live-stats
  .event-stats-football-shots__content
  > div:first-child
  span:first-child {
  left: 17%;
}
@media (max-width: 767px) {
  .event-header-live-stats
    .event-stats-football-shots__content
    > div:first-child
    span:first-child {
    left: 50%;
  }
}
.aside
  .event-header-live-stats
  .event-stats-football-shots__content
  > div:first-child
  span:first-child,
.is-mobile
  .event-header-live-stats
  .event-stats-football-shots__content
  > div:first-child
  span:first-child {
  left: 50%;
}
.event-stats-football-shots__content > div img {
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 100px;
  height: auto;
}
@media (max-width: 767px) {
  .event-stats-football-shots__content > div img {
    max-width: 120px;
  }
}
.event-header .event-stats-football-shots__content > div img {
  max-width: 96px;
}
.aside .event-stats-football-shots__content > div img,
.is-mobile .event-stats-football-shots__content > div img {
  max-width: 120px;
}
.event-stats-football-shots__content > div:first-child span:last-child {
  position: absolute;
  top: calc(50% + 10px);
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .event-stats-football-shots__content > div:first-child span:last-child {
    top: 70%;
  }
}
.event-header
  .event-stats-football-shots__content
  > div:first-child
  span:last-child {
  top: 70%;
}
.aside .event-stats-football-shots__content > div:first-child span:last-child,
.is-mobile
  .event-stats-football-shots__content
  > div:first-child
  span:last-child {
  top: 70%;
}
.event-stats-football-shots__content > div:first-child:after {
  content: "";
  transform: skew(-18deg);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  background: #4575d6;
  right: 0;
  z-index: 2;
}
.event-stats-football-shots__content > div:last-child {
  background-color: #37996f;
  color: #37996f;
}
.event-header-live-stats .event-stats-football-shots__content > div:last-child {
  background-color: #de4e52;
  color: #de4e52;
}
.event-stats-football-shots__content > div:last-child span:first-child {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .event-stats-football-shots__content > div:last-child span:first-child {
    left: 50%;
    top: 20%;
  }
}
.event-header
  .event-stats-football-shots__content
  > div:last-child
  span:first-child {
  right: 0;
  top: 52%;
}
.aside .event-stats-football-shots__content > div:last-child span:first-child,
.is-mobile
  .event-stats-football-shots__content
  > div:last-child
  span:first-child {
  left: 50%;
  top: 20%;
}
.event-header-live-stats
  .event-stats-football-shots__content
  > div:last-child
  span:first-child {
  right: 2%;
}
.event-stats-football-shots__content > div:last-child span:last-child {
  position: absolute;
  top: calc(50% + 10px);
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .event-stats-football-shots__content > div:last-child span:last-child {
    top: 70%;
  }
}
.event-header
  .event-stats-football-shots__content
  > div:last-child
  span:last-child {
  top: 70%;
}
.aside .event-stats-football-shots__content > div:last-child span:last-child,
.is-mobile
  .event-stats-football-shots__content
  > div:last-child
  span:last-child {
  top: 70%;
}
.event-stats-football-shots__content > div:last-child:after {
  content: "";
  transform: skew(-18deg);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  background: #37996f;
  border-left: 15px solid #fff;
  left: -5px;
}
.event-header-live-stats
  .event-stats-football-shots__content
  > div:last-child:after {
  background: #de4e52;
  border-left: 15px solid #37996f;
}
.event-live-stats-icons-football {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.event-stats-icon {
  font-family: Montserrat, "Montserrat", sans-serif;
  font-weight: 700;
  font-style: italic;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.event-live-stats-icons-football .event-stats-icon:first-child {
  justify-content: flex-start;
}
.event-stats-icon > div {
  height: 32px;
  width: 32px;
  position: relative;
  background-color: #283e5b;
  border-radius: 100px;
  margin: 0 10px;
}
.event-stats-icon > div i,
.event-stats-icon > div img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.event-live-stats-icons-football .event-stats-icon:nth-child(2) {
  justify-content: center;
}
.event-live-stats-icons-football .event-stats-icon:nth-child(2):before,
.event-live-stats-icons-football .event-stats-icon:nth-child(3):before {
  display: block;
  content: "";
  height: 16px;
  border-left: 1px solid #283e5b;
}
.event-live-stats-icons-football
  .event-stats-icon:nth-child(2)
  span:first-child {
  margin-left: auto;
}
.icon-card-yellow {
  color: yellow;
}
.event-live-stats-icons-football
  .event-stats-icon:nth-child(2)
  span:last-child {
  margin-right: auto;
}
.event-live-stats-icons-football .event-stats-icon:nth-child(3) {
  justify-content: flex-end;
}
.event-live-stats-icons-football .event-stats-icon:nth-child(3):before {
  margin-right: auto;
}
.event-live-stats-icons-football .event-stats-icon:nth-child(2):before,
.event-live-stats-icons-football .event-stats-icon:nth-child(3):before {
  display: block;
  content: "";
  height: 16px;
  border-left: 1px solid #283e5b;
}
.icon-card-red {
  color: red;
}
