.event-header-in-play-widgets {
  max-width: 690px;
  margin: 0 auto;
  padding: 60px 15px 0;
}
@media (max-width: 767px) {
  .event-header-in-play-widgets {
    padding: 0;
    background: linear-gradient(180deg, #37996f, #007b46);
    /* background: #37996f; */
    max-width: 100%;
  }
}
.aside .event-header-in-play-widgets,
.is-mobile .event-header-in-play-widgets {
  padding: 0;
}
.event-header-in-play-widgets-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  position: relative;
  padding: 8px 15px;
  margin-bottom: 25px;
  overflow: hidden;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
}
@media (max-width: 767px) {
  .event-header-in-play-widgets-nav {
    display: flex;
    min-height: 0;
    margin-bottom: 5px;
  }
}
.is-desktop .event-header-in-play-widgets-nav {
  box-shadow: 0 0 0 #0000;
}
.event-header-in-play-widgets-nav__inner {
  position: relative;
}
@media (max-width: 767px) {
  .event-header-in-play-widgets-nav__inner {
    width: 100%;
  }
}
.event-header-in-play-widgets-nav__buttons {
  display: flex;
  flex-direction: row;
}
@media (max-width: 767px) {
  .event-header-in-play-widgets-nav__buttons {
    justify-content: space-around;
    margin-top: 0px;
  }
}
.event-header-in-play-widgets-nav__buttons button {
  font-family: "SF Pro", "SF Pro TH", Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
}
.event-header-in-play-widgets-nav__buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  height: 32px;
  border: 1px solid rgba(111, 140, 170, 0.8);
  text-transform: capitalize;
  background: rgba(17, 38, 67, 0.8);
  font-size: 13px;
  color: #fff;
}
@media (max-width: 767px) {
  .event-header-in-play-widgets-nav__buttons button {
    min-width: 0;
    height: 18px;
    padding: 0 10px;
    border: 0;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.6);
  }
}
.event-header-in-play-widgets-nav__buttons button:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
@media (max-width: 767px) {
  .event-header-in-play-widgets-nav__buttons button + button {
    /* border-left: 1px solid #283e5b; */
  }
}
.event-header-in-play-widgets-nav__buttons button.active {
  background: #b9b9b9;
}
@media (max-width: 767px) {
  .event-header-in-play-widgets-nav__buttons button.active {
    color: #fff;
    background-color: transparent;
  }
}
.event-header-in-play-widgets-nav__buttons button:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.event-header-in-play-widgets-nav__switch {
  display: none;
  position: absolute;
  left: 100%;
  top: 100%;
  transform: translateY(-50%);
  margin-left: 15px;
  font-size: 0;
}
@media (max-width: 767px) {
  .event-header-in-play-widgets-nav__switch {
    left: auto;
    right: 0;
  }
}
.toggle {
  display: inline-block;
}
.toggle__inner {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.toggle input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.toggle__handle-container {
  display: flex;
  align-items: center;
  padding: 0 1px;
  border: 1px solid #e1e7ed;
  border-radius: 14px;
  background: #fff;
  transition: background-color 0.2s ease;
}
.toggle--primary .toggle__handle-container {
  background-color: rgba(111, 140, 170, 0.5);
  border: 0 !important;
}
.toggle--primary input.checked + .toggle__handle-container {
  background-color: rgb(255 255 255 / 50%);
}
.toggle__handle-container {
  width: 30px;
  height: 10px;
  border-color: rgb(255 255 255);
}
.toggle__handle {
  display: block;
  position: relative;
  border-radius: 50%;
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 18px;
  height: 18px;
}
.toggle--primary .toggle__handle {
  position: relative;
  background: #b9b9b9;
}
input.checked + .toggle__handle-container .toggle__handle {
  background: #fff;
}
.toggle--primary input.checked + .toggle__handle-container .toggle__handle {
  background-color: #fff;
  transform: translate3d(12px, 0, 0) !important;
}
.toggle--primary
  input.checked
  + .toggle__handle-container
  .toggle__handle:after {
  opacity: 1;
}
