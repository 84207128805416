@import url("assets/fonts/fonts.css");
@import "semantic-ui-css/semantic.min.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
body {
  margin: 0px;
  font-family: "SF Pro", "SF Pro TH", Arial, sans-serif;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (min-width: 1280px) {
  .container {
    padding: 0 20px;
    margin: 0 auto;
  }
}
@media (min-width: 1024px) {
  .container {
    padding: 0 10px;
    margin: 0 auto;
  }
}
.container {
  display: flex;
  max-width: 1320px;
}
.sport-icon-container {
  height: 25px;
}
.main-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.wrapper {
  position: relative;
  display: flex;
  /* min-height: 100vh; */
  flex-direction: column;
  padding-top: 55px;
  background: #f5f7f9;
  overflow-anchor: none;
}

.is-desktop .is-mobile {
  padding-bottom: 0px;
}
.is-desktop .bottom-bar {
  display: flex;
}
@media screen and (min-width: 768px) {
  .is-desktop .bottom-bar {
    display: flex;
  }
}
@media screen and (min-width: 1024px) {
  .is-desktop .bottom-bar {
    display: none;
  }
}
.wrapper.is-mobile {
  padding-top: 0;
  width: 100%;
}
/* Using media query for screen sizes */
@media screen and (min-width: 768px) {
  .wrapper .is-mobile-or-tablet,
  .wrapper.is-mobile {
    /* display: none; */
  }
}

@media screen and (max-width: 767px) {
  .wrapper.is-desktop .header-desktop,
  .wrapper.is-desktop .breadcrumb-bar {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .wrapper.is-desktop .sidebar {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .nav-calendar-desktop li:nth-child(1),
  .nav-calendar-desktop li:nth-child(5) {
    display: none;
  }
}
.is-mobile .wrapper__inner {
  width: 100%;
  flex: 1;
  min-height: 90vh;
}
.is-desktop .wrapper__inner {
  min-height: 95vh;
}
blockquote,
body,
dd,
dl,
dt,
fieldset,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
html,
iframe,
legend,
li,
ol,
p,
pre,
textarea,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
