.teamLogoBox {
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}

.teamLogoBox img {
  width: 100%;
  height: auto;
}
.pop-box {
  transform: translateY(10px);
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -o-transform: translateY(10px);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  /* display: none; */
  width: 300px;
  left: 30%;
  background-color: #fff;
  z-index: 2;
}

.pop-box .name-box {
  align-items: center;
  padding: 0 8px;
  display: flex;
  height: 32px;
  background-color: #e1e7ed;
}

.pop-box .name-box .text {
  font-family: "SF Pro", "SF Pro TH", Arial, sans-serif;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 14px;
  overflow: hidden;
  font-weight: 400;
  font-size: 13px;
  min-width: 0;
  color: #333;
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
}

.pop-box .name-box .text.away {
  text-align: right;
}

.pop-box .name-box .text .teamLogoHomeBox {
  margin-right: 4px;
}

.pop-box .name-box .text .teamLogoAwayBox {
  margin-right: 0;
}

.pop-box .name-box .text .hintBoxName {
  display: inline-block;
  width: 107px;
}

.pop-box .name-box .text .hintBoxNameHome {
  text-align: left;
}

.pop-box .name-box .text .hintBoxNameAway {
  text-align: right;
  margin-right: 6px;
}

.pop-box .name-box .home {
  margin-right: 12px;
}

.pop-box .incidents-box {
  display: block;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0 12px;
}

.pop-box .incidents-box .item {
  align-items: center;
  display: flex;
  height: 36px;
  border-bottom: 1px solid #f1f1f1;
}

.pop-box .incidents-box .item:first-child .away svg,
.pop-box .incidents-box .item:first-child .home svg {
  animation-name: incidents-icon-data-v-f68f0cd8;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.03s;
  -webkit-animation-name: incidents-icon-data-v-f68f0cd8;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.03s;
  -moz-animation-name: incidents-icon-data-v-f68f0cd8;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.03s;
  -o-animation-name: incidents-icon-data-v-f68f0cd8;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.03s;
}

.pop-box .incidents-box .item:nth-child(2) .away svg,
.pop-box .incidents-box .item:nth-child(2) .home svg {
  animation-name: incidents-icon-data-v-f68f0cd8;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.06s;
  -webkit-animation-name: incidents-icon-data-v-f68f0cd8;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.06s;
  -moz-animation-name: incidents-icon-data-v-f68f0cd8;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.06s;
  -o-animation-name: incidents-icon-data-v-f68f0cd8;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.06s;
}

.pop-box .incidents-box .item:nth-child(3) .away svg,
.pop-box .incidents-box .item:nth-child(3) .home svg {
  animation-name: incidents-icon-data-v-f68f0cd8;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.09s;
  -webkit-animation-name: incidents-icon-data-v-f68f0cd8;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.09s;
  -moz-animation-name: incidents-icon-data-v-f68f0cd8;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.09s;
  -o-animation-name: incidents-icon-data-v-f68f0cd8;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.09s;
}

.pop-box .incidents-box .item:nth-child(4) .away svg,
.pop-box .incidents-box .item:nth-child(4) .home svg {
  animation-name: incidents-icon-data-v-f68f0cd8;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.12s;
  -webkit-animation-name: incidents-icon-data-v-f68f0cd8;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.12s;
  -moz-animation-name: incidents-icon-data-v-f68f0cd8;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.12s;
  -o-animation-name: incidents-icon-data-v-f68f0cd8;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.12s;
}

.pop-box .incidents-box .item:nth-child(5) .away svg,
.pop-box .incidents-box .item:nth-child(5) .home svg {
  animation-name: incidents-icon-data-v-f68f0cd8;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.15s;
  -webkit-animation-name: incidents-icon-data-v-f68f0cd8;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.15s;
  -moz-animation-name: incidents-icon-data-v-f68f0cd8;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.15s;
  -o-animation-name: incidents-icon-data-v-f68f0cd8;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.15s;
}

.pop-box .incidents-box .item:nth-child(6) .away svg,
.pop-box .incidents-box .item:nth-child(6) .home svg {
  animation-name: incidents-icon-data-v-f68f0cd8;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.18s;
  -webkit-animation-name: incidents-icon-data-v-f68f0cd8;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.18s;
  -moz-animation-name: incidents-icon-data-v-f68f0cd8;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.18s;
  -o-animation-name: incidents-icon-data-v-f68f0cd8;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.18s;
}

.pop-box .incidents-box .item:nth-child(7) .away svg,
.pop-box .incidents-box .item:nth-child(7) .home svg {
  animation-name: incidents-icon-data-v-f68f0cd8;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.21s;
  -webkit-animation-name: incidents-icon-data-v-f68f0cd8;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.21s;
  -moz-animation-name: incidents-icon-data-v-f68f0cd8;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.21s;
  -o-animation-name: incidents-icon-data-v-f68f0cd8;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.21s;
}

.pop-box .incidents-box .item:nth-child(8) .away svg,
.pop-box .incidents-box .item:nth-child(8) .home svg {
  animation-name: incidents-icon-data-v-f68f0cd8;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.24s;
  -webkit-animation-name: incidents-icon-data-v-f68f0cd8;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.24s;
  -moz-animation-name: incidents-icon-data-v-f68f0cd8;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.24s;
  -o-animation-name: incidents-icon-data-v-f68f0cd8;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.24s;
}

.pop-box .incidents-box .item:nth-child(9) .away svg,
.pop-box .incidents-box .item:nth-child(9) .home svg {
  animation-name: incidents-icon-data-v-f68f0cd8;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.27s;
  -webkit-animation-name: incidents-icon-data-v-f68f0cd8;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.27s;
  -moz-animation-name: incidents-icon-data-v-f68f0cd8;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.27s;
  -o-animation-name: incidents-icon-data-v-f68f0cd8;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.27s;
}

.pop-box .incidents-box .item:nth-child(10) .away svg,
.pop-box .incidents-box .item:nth-child(10) .home svg {
  animation-name: incidents-icon-data-v-f68f0cd8;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
  -webkit-animation-name: incidents-icon-data-v-f68f0cd8;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.3s;
  -moz-animation-name: incidents-icon-data-v-f68f0cd8;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.3s;
  -o-animation-name: incidents-icon-data-v-f68f0cd8;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.3s;
}

.pop-box .incidents-box .item:nth-child(11) .away svg,
.pop-box .incidents-box .item:nth-child(11) .home svg {
  animation-name: incidents-icon-data-v-f68f0cd8;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.33s;
  -webkit-animation-name: incidents-icon-data-v-f68f0cd8;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.33s;
  -moz-animation-name: incidents-icon-data-v-f68f0cd8;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.33s;
  -o-animation-name: incidents-icon-data-v-f68f0cd8;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.33s;
}

.pop-box .incidents-box .item:nth-child(12) .away svg,
.pop-box .incidents-box .item:nth-child(12) .home svg {
  animation-name: incidents-icon-data-v-f68f0cd8;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.36s;
  -webkit-animation-name: incidents-icon-data-v-f68f0cd8;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.36s;
  -moz-animation-name: incidents-icon-data-v-f68f0cd8;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.36s;
  -o-animation-name: incidents-icon-data-v-f68f0cd8;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.36s;
}

.pop-box .incidents-box .item:nth-child(13) .away svg,
.pop-box .incidents-box .item:nth-child(13) .home svg {
  animation-name: incidents-icon-data-v-f68f0cd8;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.39s;
  -webkit-animation-name: incidents-icon-data-v-f68f0cd8;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.39s;
  -moz-animation-name: incidents-icon-data-v-f68f0cd8;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.39s;
  -o-animation-name: incidents-icon-data-v-f68f0cd8;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.39s;
}

.pop-box .incidents-box .item:nth-child(14) .away svg,
.pop-box .incidents-box .item:nth-child(14) .home svg {
  animation-name: incidents-icon-data-v-f68f0cd8;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.42s;
  -webkit-animation-name: incidents-icon-data-v-f68f0cd8;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.42s;
  -moz-animation-name: incidents-icon-data-v-f68f0cd8;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.42s;
  -o-animation-name: incidents-icon-data-v-f68f0cd8;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.42s;
}

.pop-box .incidents-box .item:nth-child(15) .away svg,
.pop-box .incidents-box .item:nth-child(15) .home svg {
  animation-name: incidents-icon-data-v-f68f0cd8;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.45s;
  -webkit-animation-name: incidents-icon-data-v-f68f0cd8;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.45s;
  -moz-animation-name: incidents-icon-data-v-f68f0cd8;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.45s;
  -o-animation-name: incidents-icon-data-v-f68f0cd8;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.45s;
}

.pop-box .incidents-box .item:nth-child(16) .away svg,
.pop-box .incidents-box .item:nth-child(16) .home svg {
  animation-name: incidents-icon-data-v-f68f0cd8;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.48s;
  -webkit-animation-name: incidents-icon-data-v-f68f0cd8;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.48s;
  -moz-animation-name: incidents-icon-data-v-f68f0cd8;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.48s;
  -o-animation-name: incidents-icon-data-v-f68f0cd8;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.48s;
}

.pop-box .incidents-box .item:nth-child(17) .away svg,
.pop-box .incidents-box .item:nth-child(17) .home svg {
  animation-name: incidents-icon-data-v-f68f0cd8;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.51s;
  -webkit-animation-name: incidents-icon-data-v-f68f0cd8;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.51s;
  -moz-animation-name: incidents-icon-data-v-f68f0cd8;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.51s;
  -o-animation-name: incidents-icon-data-v-f68f0cd8;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.51s;
}

.pop-box .incidents-box .item:nth-child(18) .away svg,
.pop-box .incidents-box .item:nth-child(18) .home svg {
  animation-name: incidents-icon-data-v-f68f0cd8;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.54s;
  -webkit-animation-name: incidents-icon-data-v-f68f0cd8;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.54s;
  -moz-animation-name: incidents-icon-data-v-f68f0cd8;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.54s;
  -o-animation-name: incidents-icon-data-v-f68f0cd8;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.54s;
}

.pop-box .incidents-box .item:nth-child(19) .away svg,
.pop-box .incidents-box .item:nth-child(19) .home svg {
  animation-name: incidents-icon-data-v-f68f0cd8;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.57s;
  -webkit-animation-name: incidents-icon-data-v-f68f0cd8;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.57s;
  -moz-animation-name: incidents-icon-data-v-f68f0cd8;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.57s;
  -o-animation-name: incidents-icon-data-v-f68f0cd8;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.57s;
}

.pop-box .incidents-box .item .away,
.pop-box .incidents-box .item .home {
  display: flex;
  align-items: center;
  flex: 1;
}

.pop-box .incidents-box .item .away .penaltyKickLeft,
.pop-box .incidents-box .item .home .penaltyKickLeft {
  width: 17px;
  position: relative;
  left: 2px;
}

.pop-box .incidents-box .item .away .penaltyKickRight,
.pop-box .incidents-box .item .home .penaltyKickRight {
  width: 17px;
  position: relative;
  right: 1px;
}

.pop-box .incidents-box .item .away svg,
.pop-box .incidents-box .item .home svg {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  height: 14px;
  width: 14px;
  opacity: 0;
}

.pop-box .incidents-box .item .away.home,
.pop-box .incidents-box .item .home.home {
  justify-content: flex-end;
}

.pop-box .incidents-box .item .away.home svg,
.pop-box .incidents-box .item .home.home svg {
  transform: translateX(-10px);
  -webkit-transform: translateX(-10px);
  -moz-transform: translateX(-10px);
  -o-transform: translateX(-10px);
}

.pop-box .incidents-box .item .away.away,
.pop-box .incidents-box .item .home.away {
  justify-content: flex-start;
}

.pop-box .incidents-box .item .away.away svg,
.pop-box .incidents-box .item .home.away svg {
  transform: translateX(10px);
  -webkit-transform: translateX(10px);
  -moz-transform: translateX(10px);
  -o-transform: translateX(10px);
}

.pop-box .incidents-box .item .away .playerName,
.pop-box .incidents-box .item .home .playerName {
  max-width: 70px;
  font-size: 12px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #999;
}

.pop-box .incidents-box .item .away .homePlayerName,
.pop-box .incidents-box .item .home .homePlayerName {
  text-align: right;
  margin-right: 12px;
}

.pop-box .incidents-box .item .away .AwayPlayerName,
.pop-box .incidents-box .item .home .AwayPlayerName {
  margin-left: 12px;
}

.pop-box .incidents-box .item .text {
  font-family: "SF Pro", "SF Pro TH", Arial, sans-serif;
  text-align: center;
  line-height: 12px;
  font-weight: 400;
  font-size: 13px;
  width: 45px;
  color: #333;
}

.pop-box .tips {
  font-family: "SF Pro", "SF Pro TH", Arial, sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  padding: 0 10px;
  display: block;
  color: #333;
  margin-top: 16px;
}

.pop-box .stats-box {
  display: block;
  background-color: #fff;
  color: #333;
  box-sizing: border-box;
  padding: 0 12px;
}

.pop-box .stats-box .possessionText {
  display: inline-block;
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  margin: 16px 0 6px;
}

.pop-box .stats-box .possession {
  display: block;
  font-size: 0;
  margin-bottom: 10px;
}

.pop-box .stats-box .possession span {
  display: inline-block;
  height: 18px;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  box-sizing: border-box;
}

.pop-box .stats-box .possession .possessionHome {
  background-color: #3a7ed3;
  padding-left: 6px;
}

.pop-box .stats-box .possession .possessionAway {
  background-color: #f3b140;
  text-align: right;
  padding-right: 6px;
}
.stats-item {
  align-items: center;
  min-height: 32px;
  display: flex;
  border-bottom: 1px solid #f1f1f1;
}

.stats-item .away,
.stats-item .home {
  align-items: center;
  display: flex;
  flex: 1;
}

.stats-item .away .number,
.stats-item .home .number {
  font-family: Roboto-Regular, Roboto;
  white-space: nowrap;
  font-weight: 400;
  font-size: 13px;
  width: 22px;
  color: #333;
  text-align: center;
  border-radius: 2px;
}

.stats-item .away .numberHomeColor,
.stats-item .home .numberHomeColor {
  background-color: rgba(58, 126, 211, 0.12);
}

.stats-item .away .numberAwayColor,
.stats-item .home .numberAwayColor {
  background-color: rgba(243, 177, 64, 0.12);
}

.stats-item .away .bar,
.stats-item .home .bar {
  background-color: #000;
  position: relative;
  border-radius: 2px;
  height: 4px;
  flex: 1;
}

.stats-item .away .bar span,
.stats-item .home .bar span {
  position: absolute;
  height: 100%;
  top: 0;
}

.stats-item .away .bar span:before,
.stats-item .home .bar span:before {
  background-color: #0f80da;
  border-radius: 2px;
  position: absolute;
  height: 100%;
  content: "";
  opacity: 0;
  width: 0;
  top: 0;
}

.stats-item .away.home .bar span,
.stats-item .away.home .bar span:before,
.stats-item .home.home .bar span,
.stats-item .home.home .bar span:before {
  right: 0;
}

.stats-item .away.away,
.stats-item .home.away {
  justify-content: flex-end;
}

.stats-item .away.away .number,
.stats-item .home.away .number {
  margin-left: 8px;
}

.stats-item .away.away .bar span,
.stats-item .away.away .bar span:before,
.stats-item .home.away .bar span,
.stats-item .home.away .bar span:before {
  left: 0;
}

.stats-item .text {
  font-family: Roboto-Regular, Roboto;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  margin: 0 6px;
  width: 90px;
  color: #333;
  flex: 2;
}

.stats-item:first-child .away .bar span:before,
.stats-item:first-child .home .bar span:before {
  animation-name: stats-width-data-v-83df7650;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.03s;
  -webkit-animation-name: stats-width-data-v-83df7650;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.03s;
  -moz-animation-name: stats-width-data-v-83df7650;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.03s;
  -o-animation-name: stats-width-data-v-83df7650;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.03s;
}

.stats-item:nth-child(2) .away .bar span:before,
.stats-item:nth-child(2) .home .bar span:before {
  animation-name: stats-width-data-v-83df7650;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.06s;
  -webkit-animation-name: stats-width-data-v-83df7650;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.06s;
  -moz-animation-name: stats-width-data-v-83df7650;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.06s;
  -o-animation-name: stats-width-data-v-83df7650;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.06s;
}

.stats-item:nth-child(3) .away .bar span:before,
.stats-item:nth-child(3) .home .bar span:before {
  animation-name: stats-width-data-v-83df7650;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.09s;
  -webkit-animation-name: stats-width-data-v-83df7650;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.09s;
  -moz-animation-name: stats-width-data-v-83df7650;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.09s;
  -o-animation-name: stats-width-data-v-83df7650;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.09s;
}

.stats-item:nth-child(4) .away .bar span:before,
.stats-item:nth-child(4) .home .bar span:before {
  animation-name: stats-width-data-v-83df7650;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.12s;
  -webkit-animation-name: stats-width-data-v-83df7650;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.12s;
  -moz-animation-name: stats-width-data-v-83df7650;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.12s;
  -o-animation-name: stats-width-data-v-83df7650;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.12s;
}

.stats-item:nth-child(5) .away .bar span:before,
.stats-item:nth-child(5) .home .bar span:before {
  animation-name: stats-width-data-v-83df7650;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.15s;
  -webkit-animation-name: stats-width-data-v-83df7650;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.15s;
  -moz-animation-name: stats-width-data-v-83df7650;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.15s;
  -o-animation-name: stats-width-data-v-83df7650;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.15s;
}

.stats-item:nth-child(6) .away .bar span:before,
.stats-item:nth-child(6) .home .bar span:before {
  animation-name: stats-width-data-v-83df7650;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.18s;
  -webkit-animation-name: stats-width-data-v-83df7650;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.18s;
  -moz-animation-name: stats-width-data-v-83df7650;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.18s;
  -o-animation-name: stats-width-data-v-83df7650;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.18s;
}

.stats-item:nth-child(7) .away .bar span:before,
.stats-item:nth-child(7) .home .bar span:before {
  animation-name: stats-width-data-v-83df7650;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.21s;
  -webkit-animation-name: stats-width-data-v-83df7650;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.21s;
  -moz-animation-name: stats-width-data-v-83df7650;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.21s;
  -o-animation-name: stats-width-data-v-83df7650;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.21s;
}

.stats-item:nth-child(8) .away .bar span:before,
.stats-item:nth-child(8) .home .bar span:before {
  animation-name: stats-width-data-v-83df7650;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.24s;
  -webkit-animation-name: stats-width-data-v-83df7650;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.24s;
  -moz-animation-name: stats-width-data-v-83df7650;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.24s;
  -o-animation-name: stats-width-data-v-83df7650;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.24s;
}

.stats-item:nth-child(9) .away .bar span:before,
.stats-item:nth-child(9) .home .bar span:before {
  animation-name: stats-width-data-v-83df7650;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.27s;
  -webkit-animation-name: stats-width-data-v-83df7650;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.27s;
  -moz-animation-name: stats-width-data-v-83df7650;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.27s;
  -o-animation-name: stats-width-data-v-83df7650;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.27s;
}

.stats-item:nth-child(10) .away .bar span:before,
.stats-item:nth-child(10) .home .bar span:before {
  animation-name: stats-width-data-v-83df7650;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
  -webkit-animation-name: stats-width-data-v-83df7650;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.3s;
  -moz-animation-name: stats-width-data-v-83df7650;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.3s;
  -o-animation-name: stats-width-data-v-83df7650;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.3s;
}

.stats-item:nth-child(11) .away .bar span:before,
.stats-item:nth-child(11) .home .bar span:before {
  animation-name: stats-width-data-v-83df7650;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.33s;
  -webkit-animation-name: stats-width-data-v-83df7650;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.33s;
  -moz-animation-name: stats-width-data-v-83df7650;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.33s;
  -o-animation-name: stats-width-data-v-83df7650;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.33s;
}

.stats-item:nth-child(12) .away .bar span:before,
.stats-item:nth-child(12) .home .bar span:before {
  animation-name: stats-width-data-v-83df7650;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.36s;
  -webkit-animation-name: stats-width-data-v-83df7650;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.36s;
  -moz-animation-name: stats-width-data-v-83df7650;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.36s;
  -o-animation-name: stats-width-data-v-83df7650;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.36s;
}

.stats-item:nth-child(13) .away .bar span:before,
.stats-item:nth-child(13) .home .bar span:before {
  animation-name: stats-width-data-v-83df7650;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.39s;
  -webkit-animation-name: stats-width-data-v-83df7650;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.39s;
  -moz-animation-name: stats-width-data-v-83df7650;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.39s;
  -o-animation-name: stats-width-data-v-83df7650;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.39s;
}

.stats-item:nth-child(14) .away .bar span:before,
.stats-item:nth-child(14) .home .bar span:before {
  animation-name: stats-width-data-v-83df7650;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.42s;
  -webkit-animation-name: stats-width-data-v-83df7650;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.42s;
  -moz-animation-name: stats-width-data-v-83df7650;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.42s;
  -o-animation-name: stats-width-data-v-83df7650;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.42s;
}

.stats-item:nth-child(15) .away .bar span:before,
.stats-item:nth-child(15) .home .bar span:before {
  animation-name: stats-width-data-v-83df7650;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.45s;
  -webkit-animation-name: stats-width-data-v-83df7650;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.45s;
  -moz-animation-name: stats-width-data-v-83df7650;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.45s;
  -o-animation-name: stats-width-data-v-83df7650;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.45s;
}

.stats-item:nth-child(16) .away .bar span:before,
.stats-item:nth-child(16) .home .bar span:before {
  animation-name: stats-width-data-v-83df7650;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.48s;
  -webkit-animation-name: stats-width-data-v-83df7650;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.48s;
  -moz-animation-name: stats-width-data-v-83df7650;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.48s;
  -o-animation-name: stats-width-data-v-83df7650;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.48s;
}

.stats-item:nth-child(17) .away .bar span:before,
.stats-item:nth-child(17) .home .bar span:before {
  animation-name: stats-width-data-v-83df7650;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.51s;
  -webkit-animation-name: stats-width-data-v-83df7650;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.51s;
  -moz-animation-name: stats-width-data-v-83df7650;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.51s;
  -o-animation-name: stats-width-data-v-83df7650;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.51s;
}

.stats-item:nth-child(18) .away .bar span:before,
.stats-item:nth-child(18) .home .bar span:before {
  animation-name: stats-width-data-v-83df7650;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.54s;
  -webkit-animation-name: stats-width-data-v-83df7650;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.54s;
  -moz-animation-name: stats-width-data-v-83df7650;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.54s;
  -o-animation-name: stats-width-data-v-83df7650;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.54s;
}

.stats-item:nth-child(19) .away .bar span:before,
.stats-item:nth-child(19) .home .bar span:before {
  animation-name: stats-width-data-v-83df7650;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.57s;
  -webkit-animation-name: stats-width-data-v-83df7650;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.57s;
  -moz-animation-name: stats-width-data-v-83df7650;
  -moz-animation-duration: 0.3s;
  -moz-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.57s;
  -o-animation-name: stats-width-data-v-83df7650;
  -o-animation-duration: 0.3s;
  -o-animation-timing-function: cubic-bezier(0, 1.08, 0.33, 1.14);
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-animation-delay: 0.57s;
}
