.match-list-head {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: #007b46;
  color: #fff;
}
.bg-secondary {
  background-color: #007b46;
  color: #fff;
}
@media (min-width: 1024px){
.content-header-label {
    border-top-left-radius: 8px;
}
}
.content-header-label {
  min-width: 0;
  padding: 14px 10px 14px 15px;
}
.content-header-label__title {
  display: flex;
  align-items: center;
  flex-grow: 1;
  /* font-family: Montserrat,"Montserrat",sans-serif; */
  font-weight: 700;
  font-style: normal;
  font-size: 22px;
  /* line-height: 1.25; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: currentColor;
}
.match-list-head__name {
  display: inline;
  align-items: center;
  padding: 6px 15px;
  padding-bottom: 12px;
  /* font-family: Montserrat, "Montserrat", sans-serif; */
  font-weight: 700;
  /* font-style: italic; */
  /* font-size: 16px; */
  line-height: 1;
  color: inherit;
}
.t-uppercase {
  text-transform: uppercase;
}
.match-list-head__icon {
  font-size: 20px;
  line-height: 28px;
  margin-right: 8px;
  /* color: #00b1ff; */
}
.popover {
  position: relative;
}
.match-list-head__action {
  display: inline-flex;
  align-items: center;
  position: relative;
  /* padding: 11px; */
  margin-left: 15px;
  /* background-color: #3ab26d; */
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  transition: color 0.2s ease-in-out;
}
.match-list-head__action:before {
  display: none;
  content: "";
  position: absolute;
  height: 100%;
  top: 0;
  left: -12px;
  border-color: transparent transparent #3ab26d;
  border-style: solid;
  border-width: 0 0 40px 12px;
}
.match-list-head__action--link {
  text-transform: uppercase;
  font-size: 12px;
  font-family: Montserrat, "Montserrat", sans-serif;
  font-weight: 700;
  font-style: italic;
}
.popover__trigger {
  cursor: pointer;
}
.media-package-badge {
  color: #fff;
}
.t-center {
  text-align: center !important;
}
.align-center {
  align-items: center;
}
.d-flex {
  display: flex;
}
.d-none {
  display: none !important;
}
.media-package-badge__label {
  margin-right: 7px;
}
@media (min-width: 768px) {
  .d-sm-block {
    display: block !important;
  }
}
.media-package-badge__btn {
  position: relative;
  padding: 0 35px 0 5px;
  height: 22px;
  border-radius: 4px;
}
.media-package-badge__btn.cursor-pointer {
  background-color: rgb(255 255 255 / 0%);
}
.cursor-pointer {
  cursor: pointer;
}
.media-package-badge__btn > img {
  width: 40px;
  height: 20px;
  object-fit: contain;
  image-rendering: -webkit-optimize-contrast;
}
@media (min-width: 768px) {
  .media-package-badge__btn > img {
    width: 58px;
  }
}
.media-package-badge__btn__icon {
  position: absolute;
  top: -2px;
  right: 8px;
  display: block;
  width: 26px;
  height: 26px;
  border: 2px solid;
  font-size: 14px;
  line-height: 22px;
}
.u-circle {
  border-radius: 50%;
}
.flex-center {
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.gift-icon {
  position: absolute;
  left: 3px;
}
