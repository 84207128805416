/* MatchListSubNav.css */
.match-list-sub-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 10px;
  font-family: "SF Pro", "SF Pro TH", Arial, sans-serif;
  color: #6f8caa;
}
@keyframes tada-keyframes {
  0% {
    transform: scale3d(1, 1, 1);
  }
  10%,
  5% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -5deg);
  }
  15%,
  25%,
  35%,
  45% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 5deg);
  }
  20%,
  30%,
  40% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -5deg);
  }
  50% {
    transform: scale3d(1, 1, 1);
  }
}
.icon-timer {
  display: block;
  transition: all 0.15s ease 0s;
  animation: tada-keyframes 3s ease infinite;
}
.match-list-filter-buttons {
  display: flex;
  align-items: center;
}
.btn-filter {
  display: block;
  padding: 0 10px;
  background: #f5f7f9;
  font-size: 13px;
  line-height: 1.08;
  font-family: "SF Pro", "SF Pro TH", Arial, sans-serif;
  color: #6f8caa;
  white-space: nowrap;
  text-transform: none;
}
.btn-filter:hover {
  background: #e1e7ed;
  color: #007b46;
}
.btn-filter.active {
  background: #007b46;
  color: #f5f7f9;
}

.match-list-filter-buttons button:first-child {
  margin-right: 10px;
}
.btn-filter__icon {
  font-size: 13px;
  margin-right: 5px;
  color: #00b1ff;
  transition: 0.2s;
  font-style: normal;
}
.btn,
.btn-filter {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  border-radius: 80px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  transition: 0.2s;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  margin-right: 10px;
}
.btn-filter .btn__label {
  top: -6px;
}
.is-mobile .sortByBox {
  display: none;
}
.is-desktop .btn-filter.mobile {
  display: none;
}
.bg-danger {
  background-color: #f33c59 !important;
}
.btn__label {
  position: absolute;
  top: -13px;
  left: auto;
  bottom: auto;
  right: -2px;
  z-index: 0;
  background: #37996f;
  color: #fff;
  border-radius: 8px;
  padding: 2px 4px;
  font-size: 8px;
  line-height: 1;
  border: 2px solid #fff;
}
.nav-calendar-desktop {
  display: flex;
  align-items: center;
}
.m-0 {
  margin: 0 !important;
}
.nav-calendar-desktop__item:not(:last-child) {
  margin-right: 2px;
}
.nav-calendar-desktop__item a {
  position: relative;
  z-index: 1;
  display: block;
  width: 40px;
  height: 36px;
  border-radius: 80px;
  /* color: #6f8caa; */
  font-size: 9px;
  line-height: 1.3;
  text-align: center;
  padding: 2px 0 0;
  text-transform: uppercase;
}
.nav-calendar-desktop__item.active a,
.nav-calendar-desktop__item.active a:hover {
  background: #007b46;
  color: #fff;
}
.nav-calendar-desktop__item a span {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: inherit;
}
.nav-calendar-desktop__item:last-child input {
  position: absolute;
  bottom: 0;
  right: 0;
}
.nav-calendar-desktop__item:last-child {
  position: relative;
}
.nav-calendar-desktop__item input {
  width: 0;
  height: 0;
  padding: 0;
  border: none;
  outline: none;
  box-shadow: none;
}
.nav-calendar-desktop__item a i {
  font-size: 18px;
  line-height: 30px;
}
.nav-calendar-desktop__item a:hover {
  color: #00b1ff;
}
.nav-calendar-desktop__item {
  display: flex;
  flex-direction: row-reverse;
}
.is-mobile .hide-item {
  display: none;
}
.nav-calendar-desktop__item.disabled {
  pointer-events: none;
  /* opacity: 0.6; */
}
.btn-sort_by_time {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  border-radius: 80px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  transition: 0.2s;
  cursor: pointer;
  appearance: none;
  font-size: 18px;
  line-height: 30px;
  /* margin-right: 5px; */
  padding-left: 0px;
  color: #007b46;
}
.sortByBox {
  display: inline-flex;
  align-items: center;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #eee;
  box-sizing: border-box;
  font-size: 13px;
  cursor: pointer;
  margin-left: 14px;
  padding-right: 16px;
}
.sortByBox {
  max-width: 135px;
}
.el-checkbox {
  color: #606266;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  margin-right: 30px;
}
.sortByBox .el-checkbox {
  position: relative;
  /* top: 1px; */
  margin: 0 8px 0 16px;
}
.el-checkbox,
.el-checkbox__input {
  display: inline-block;
  position: relative;
  white-space: nowrap;
}
.el-checkbox__input {
  cursor: pointer;
  outline: 0;
  line-height: 1;
  vertical-align: middle;
}
.sortByBox .sortByText {
  display: inline-block;
  max-width: 80px;
  position: relative;
  bottom: 0;
}
.bh {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
