.event-section__head {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  padding: 0 15px;
}
.event-section--h2h .event-section__head,
.event-section--lineup .event-section__head {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
}
.event-section--lineup .event-section__label-confirmed {
  margin-top: -3px;
  color: #00b1ff;
}
.event-section__body {
  padding: 0 10px;
}
.event-section--lineup .event-section__body {
  padding-bottom: 10px;
}
.team-tabs {
  margin-bottom: 10px;
}
.team-tabs ul {
  display: flex;
  list-style: none outside none;
}
.team-tabs li {
  width: 50%;
}
.team-tabs button {
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 40px;
  border-radius: 8px 0 0 8px;
  background: #fff;
  line-height: 38px;
  font-size: 13px;
  font-weight: 500;
  color: #1a3150;
  border: 1px solid #e1e7ed;
  border-right-width: 0;
  transition: background 0.3s, border-color 0.3s, color 0.3s;
}
.team-tabs button.active {
  border-color: #1a3150;
  background: #1a3150;
  color: #fff;
}
.team-tabs li:last-child button {
  flex-flow: row-reverse nowrap;
  border-radius: 0 8px 8px 0;
  border-left-width: 0;
  border-right-width: 1px;
}
.team-tabs__logo {
  display: block;
  width: 54px;
  height: 40px;
  margin: -1px 0 0 -11px;
  background: #f5f7f9;
  text-align: center;
  line-height: 35px;
  transform: skewX(-18deg);
  transition: background 0.3s;
}
.active .team-tabs__logo {
  background: #0e203c;
}
.team-tabs__logo img {
  width: auto;
  height: 28px;
  max-width: 28px;
  transform: skewX(18deg);
}
.team-tabs__text {
  flex: 1;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
}
li:last-child .team-tabs__logo {
  transform: skewX(18deg);
  margin: -1px -11px 0 0;
}
li:last-child .team-tabs__logo img {
  transform: skewX(-18deg);
}
.lineup__outer {
  padding-bottom: 20px;
}
@media (max-width: 767px) {
  .lineup__outer {
    padding-bottom: 0;
  }
}
.lineup {
  position: relative;
  overflow: hidden;
  padding: 10px 34px;
  border-radius: 7px;
  background: #55bb60;
}
.lineup--single {
  padding-left: 15px;
  padding-right: 15px;
}
@media (max-width: 767px) {
  .lineup {
    border-radius: 0;
    margin-left: -10px;
    margin-right: -10px;
  }
}
.lineup__head {
  position: relative;
  overflow: hidden;
  display: flex;
  height: 22px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 1.17;
  color: #1a3150;
}
.lineup__head-inner {
  width: 50%;
  display: flex;
  align-items: center;
}
.lineup--single .lineup__head-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.3s ease-in-out;
}
.lineup--single .lineup__head-inner.active,
.lineup--single .lineup__head-inner.active:last-child {
  transform: translateZ(0);
}
.lineup__head img + span,
.lineup__head span + span {
  margin-left: 8px;
}
.lineup--single .lineup__head-inner span:last-child {
  justify-self: flex-end;
  margin-left: auto;
}
.lineup__head-inner:last-child:not(:first-child) {
  flex-flow: row-reverse nowrap;
}
.lineup--single .lineup__head-inner:last-child {
  transform: translate3d(100%, 0, 0);
}
.lineup__head-inner:last-child:not(:first-child) img + span,
.lineup__head-inner:last-child:not(:first-child) span + span {
  margin-left: 0;
  margin-right: 8px;
}
.lineup--single .lineup__head-inner:last-child span:last-child {
  margin-left: 0;
  margin-right: auto;
}
.lineup-field {
  position: relative;
  overflow: hidden;
  display: flex;
  height: 406px;
  border: 2px solid #e1e7ed;
}
.lineup-field__area {
  position: relative;
  height: 100%;
  width: 50%;
  overflow: hidden;
  will-change: transform, transition;
}
.lineup-field--single .lineup-field__area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 0.3s ease-in-out;
  transform: translate3d(-100%, 0, 0);
}
.lineup-field--single .lineup-field__area.active,
.lineup-field--single .lineup-field__area.active:last-child {
  transform: translateZ(0);
}
.lineup-field__angles {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.lineup-field__angle {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border: 2px solid #e1e7ed;
  border-radius: 50%;
  margin: -20px 0 0 -20px;
}
.lineup-field__angle:nth-child(2) {
  top: auto;
  bottom: 0;
  margin: 0 0 -20px -20px;
}
.lineup-field__zebra {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.lineup-field__zebra:after,
.lineup-field__zebra:before {
  content: "";
  position: absolute;
  top: 0;
  left: 20%;
  bottom: 0;
  width: 20%;
  background: #fff;
}
.lineup-field__zebra:after {
  left: 60%;
}
.lineup-field__circle {
  position: absolute;
  top: 50%;
  right: 0;
  width: 108px;
  height: 108px;
  border: 2px solid #e1e7ed;
  border-radius: 50%;
  margin-top: -54px;
  transform: translateX(50%);
}
.lineup-field__circle:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: auto;
  background: #e1e7ed;
}
.lineup-field__door {
  position: absolute;
  left: -2px;
  top: 50%;
  width: 39px;
  height: 138px;
  margin-top: -69px;
  border: 2px solid #e1e7ed;
}
.lineup-field__door:before {
  left: -2px;
  z-index: 3;
  width: 20px;
  height: 64px;
  margin-top: -32px;
}
.lineup-field__door:after,
.lineup-field__door:before {
  content: "";
  position: absolute;
  top: 50%;
  border: 2px solid #e1e7ed;
}
.lineup-field__door span {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: #55bb60;
}
.lineup-field__door:after {
  right: -20px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin-top: -55px;
}
.lineup-field__scheme {
  position: absolute;
  left: 16px;
  top: 22px;
  font-size: 12px;
  line-height: 14px;
  font-family: Montserrat, "Montserrat", sans-serif;
  font-weight: 700;
  font-style: italic;
  color: #1a3150;
}
.lineup-field__players {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.lineup-field__group {
  display: flex;
  flex: 1 1 1px;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.lineup-field__player {
  position: relative;
  text-align: center;
}
.lineup-field__player-head {
  position: relative;
  width: 38px;
  margin: auto;
}
.lineup-field__player-avatar {
  width: 38px;
  height: 38px;
  margin: 0 auto 2px;
  border: 2px solid #fff;
  background: #fff;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 5px 3px rgba(26, 49, 80, 0.1);
}
.lineup-field__player-avatar img {
  width: 100%;
  height: 100%;
}
.lineup-field__player-card {
  position: absolute;
  top: 20px;
  left: 50%;
  margin-left: 13px;
  width: 12px;
  white-space: nowrap;
}
.lineup-field__player-name {
  display: block;
  overflow: hidden;
  margin-bottom: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 11px;
  line-height: 1.36;
}
.lineup-field__player-number {
  display: block;
  font-size: 11px;
  line-height: 1.27;
  font-style: italic;
  font-weight: 700;
  font-family: Montserrat, "Montserrat", sans-serif;
}
.lineup-field__area--reverse {
  background: #55c361;
}
.lineup-field--single .lineup-field__area:last-child {
  transform: translate3d(100%, 0, 0);
}
.lineup-field__area--reverse .lineup-field__angle {
  left: auto;
  right: 0;
  margin: -20px -20px 0 0;
}
.lineup-field__area--reverse .lineup-field__angle {
  left: auto;
  right: 0;
  margin: -20px -20px 0 0;
}
.lineup-field__area--reverse .lineup-field__angle:nth-child(2) {
  margin: 0 -20px -20px 0;
}
.lineup-field__zebra:after,
.lineup-field__zebra:before {
  content: "";
  position: absolute;
  top: 0;
  left: 20%;
  bottom: 0;
  width: 20%;
  background: #51bb5c;
  background: #55c361;
}
.lineup-field__zebra:after {
  left: 60%;
}
.lineup-field__area--reverse .lineup-field__zebra:after,
.lineup-field__area--reverse .lineup-field__zebra:before {
  background: #55bb60b0;
}
.lineup-field__area--reverse .lineup-field__circle {
  right: auto;
  left: 0;
  transform: translateX(-50%);
}
.lineup-field__area--reverse .lineup-field__door {
  left: auto;
  right: -6px;
  transform: rotateY(180deg);
}
.lineup-field__area--reverse .lineup-field__door span {
  background: #55bb60;
}
.lineup-field__area--reverse .lineup-field__scheme {
  left: auto;
  right: 16px;
}
.lineup-field__area--reverse .lineup-field__players {
  flex-flow: row-reverse nowrap;
}
.lineup-field__area--reverse .lineup-field__group {
  flex-direction: column;
}
.lineup-field__player-status {
  position: absolute;
  top: 2px;
  right: 50%;
  margin-right: 11px;
  width: 16px;
}
.lineup-field__player-status__time {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  margin-bottom: -3px;
  font-size: 10px;
  line-height: 1;
  text-align: center;
}
.lineup-field__player-status img {
  width: 16px;
  height: 16px;
}
.lineup__foot {
  justify-content: space-between;
  padding-top: 8px;
  font-size: 12px;
  line-height: 1.3;
  color: #1a3150;
}
.lineup__foot,
.lineup__foot-inner {
  display: flex;
  align-items: center;
}
.lineup__foot i {
  display: inline-block;
  margin-right: 4px;
  font-size: 13px;
  color: #6f8caa;
}
.lineup-section__head {
  margin-bottom: 15px;
  text-align: center;
}
.lineup-section--stacked .lineup-section__title {
  justify-content: flex-start;
}
.lineup-section__title img {
  width: auto;
  height: 20px;
  margin-right: 12px;
}
@media (max-width: 767px) {
  .lineup-section__title img {
    margin-right: 15px;
  }
}
.lineup-section__body {
  display: flex;
  justify-content: space-between;
}
.lineup-section--stacked .lineup-section__body {
  display: block;
}
.lineup-section__inner {
  width: 47%;
}
.lineup-section--stacked .lineup-section__inner {
  width: 100%;
}
.lineup-section__team {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 4px 12px;
  border-radius: 8px;
  margin-bottom: 8px;
  background: #f5f7f9;
  font-family: Montserrat, "Montserrat", sans-serif;
  font-size: 14px;
  color: #1a3150;
}
@media (max-width: 767px) {
  .lineup-section__team {
    display: none;
  }
}
.lineup-section__team img {
  width: auto;
  height: 27px;
  margin-right: 10px;
}
.lineup-section__team span {
  padding-top: 2px;
}
.lineup-player--stacked {
  position: relative;
  display: flex;
  flex-flow: row wrap;
}
.lineup-player--stacked:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  height: 1px;
  background-image: linear-gradient(
    90deg,
    #e1e7ed 33%,
    rgba(225, 231, 237, 0) 0
  );
  background-position: bottom;
  background-size: 6px 1px;
  background-repeat: repeat-x;
}
.lineup-player__minute {
  width: 100%;
  margin-bottom: 4px;
  font-size: 13px;
  line-height: 1;
  font-weight: 500;
  color: #1a3150;
}
.lineup-player__minute span {
  position: relative;
  display: inline-block;
  padding: 0 6px;
  margin-left: 10px;
  background: #fff;
}
.lineup-player__inner {
  position: relative;
  padding: 0 15px 0 5px;
  display: flex;
  align-items: center;
  height: 44px;
}
.lineup-player--stacked .lineup-player__inner {
  width: 50%;
  order: 2;
}
.lineup-player__image {
  position: relative;
  flex: 0 0 34px;
  overflow: hidden;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin-right: 8px;
}
.lineup-player__image img {
  width: 100%;
  height: 100%;
  font-size: 0;
}
.lineup-player__injured img,
.lineup-player__sub {
  position: absolute;
  top: 0;
  left: 0;
}
.lineup-player__sub img {
  width: 16px;
  height: 16px;
}
.lineup-player__content {
  position: relative;
  z-index: 1;
  flex: 1;
  max-width: calc(100% - 30px);
}
.lineup-player__injury,
.lineup-player__name {
  font-weight: 500;
  font-size: 13px;
  margin: 0;
  line-height: 1.38;
  color: #1a3150;
}
.lineup-player__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lineup-player__meta {
  font-size: 12px;
  line-height: 1.17;
  color: #6f8caa;
}
.lineup-player__status {
  justify-self: flex-end;
  margin-left: auto;
  text-align: right;
}
.lineup-player__number {
  position: absolute;
  top: -7px;
  right: 0;
  font-family: Montserrat, "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: 700;
  font-style: italic;
  color: rgba(225, 231, 237, 0.6);
}
.lineup-player + .lineup-player {
  margin-top: 4px;
}
.lineup-actions {
  display: flex;
  justify-content: center;
}
.btn-full {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  font-size: 12px;
  background-color: #f5f7f9;
  transition: background-color 0.2s;
}
.montseratBold {
  font-family: Montserrat, "Montserrat", sans-serif;
  font-weight: 700;
  font-style: italic;
}
.t-uppercase {
  text-transform: uppercase;
}
.lineup-actions__btn {
  transition: 0.3s;
}
