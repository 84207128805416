.flex2 {
    display: flex;
  }
  .event-header .liveBox {
    justify-content: center;
    color: #fff;
    /* font-size: .32rem;
    font-family: Roboto-Regular,Roboto; */
    font-weight: 400;
    height: 2.5rem;
    /* margin-top: 0.43rem; */
  }
  .flex {
    display: flex;
    flex-direction: row;
  }
  .event-header .liveBox .liveSream {
    width: 8rem;
    height: 1.5rem;
    align-items: center;
    justify-content: center;
    border: 1px solid hsl(0deg 0% 100% / 50%);
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px;
  }
  .van-icon__image, .van-image, .van-image__error-icon {
    align-items: center;
    justify-content: center;
  }
  .van-image {
    position: relative;
    display: inline-block;
  }
  .van-image--round {
    overflow: hidden;
    border-radius: 50%;
  }
  .event-header .myIcon {
    width: 16px;
    height: 16px;
    text-align: center;
    color: #fff;
  }
  .van-image__error, .van-image__img, .van-image__loading {
    display: block;
    width: 100%;
    height: 100%;
  }
  .van-image--round img {
    border-radius: inherit;
  }
  .w-o-h {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 5px;
    max-width: 80px;
  }
  .event-header .liveBox .matchLive {
    width: 8rem;
    height: 1.5rem;
    align-items: center;
    justify-content: center;
    border: 1px solid hsl(0deg 0% 56.84% / 77%);
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
    border-left: none;
  }