.event-livestream-inplay {
  max-width: 690px;
  margin: auto;
}
@media (max-width: 767px) {
  .event-livestream-inplay {
    padding: 0;
  }
}
.event-livestream-inplay .livestream-steps,
.event-livestream-inplay__list li:before,
.event-livestream-inplay__title,
.event-livestream-inplay__title-mobile {
  font-family: Montserrat, "Montserrat", sans-serif;
  font-weight: 700;
  font-style: italic;
}
.event-livestream-inplay__title-mobile {
  display: none;
  margin-bottom: 10px;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
}
@media (max-width: 767px) {
  .event-livestream-inplay__title-mobile {
    display: block;
  }
}
.event-livestream-inplay__body {
  display: flex;
  /* margin-bottom: 36px; */
}
@media (max-width: 767px) {
  .event-livestream-inplay__body {
    display: block;
    margin-bottom: 0;
    /* height: 220px; */
  }
}
.event-livestream-inplay__aside {
  position: relative;
  width: 100%;
  margin-right: 0;
}
@media (max-width: 767px) {
  .event-livestream-inplay__aside {
    width: auto;
    margin: 10px 0px 0px 0px;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.livestream-cover {
  position: relative;
  /* overflow: hidden; */
  display: block;
  border-radius: 0px;
  transform: translateZ(0);
  /* height: 30vh; */
}
.is-desktop .livestream-cover {
  height: 37vh;
}
.livestream-cover__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(https://cdn.oddspedia.com/images/static/livestream-covers/football-cover.jpg)
    no-repeat 50%;
  background-size: cover;
  animation: livestreamCover 30s linear infinite;
}
.livestream-cover__image:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #132a49;
  opacity: 0.5;
  transition: 0.3s;
}
.livestream-cover__button-simple {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(https://cdn.oddspedia.com/images/static/icons/icon-play.svg);
  background-size: 100% 100%;
  background-color: rgba(0, 0, 0, 0.4);
  width: 1em;
  height: 1em;
  font-size: 68px;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .livestream-cover__button-simple {
    display: none;
  }
}
@media (max-width: 767px) {
  .event-livestream-inplay .livestream-cover__button-simple {
    font-size: 60px;
  }
}
.livestream-cover__button {
  display: none;
  position: absolute;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 36px;
  background: #37996f;
  padding: 3px;
  border-radius: 20px;
  margin-top: -10px;
  font: italic 700 12px/1.3 Montserrat, "Montserrat", sans-serif;
  color: #fff;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .livestream-cover__button {
    display: flex;
  }
}
.livestream-cover__button__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  color: #37996f;
  font-size: 0;
}
.livestream-cover__button__icon svg {
  position: relative;
  left: 1px;
}
.livestream-cover__button__text {
  flex: 1;
  padding: 0 7px;
  max-width: 0;
  padding: 0;
  overflow: hidden;
}
.animate .livestream-cover__button__text {
  animation: livestreamButtonText 1s linear forwards;
  animation-delay: 0.5s;
}
.livestream-cover__caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 5px;
  color: #fff;
}
@media (max-width: 767px) {
  .event-livestream-inplay .livestream-cover__caption {
    padding-bottom: 0;
  }
}
.livestream-cover__progress {
  position: absolute;
  bottom: 100%;
  left: 10px;
  right: 10px;
  height: 4px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
}
.livestream-cover__progress__inner {
  display: block;
  position: relative;
  height: 100%;
  width: 15%;
  border-radius: 3px;
  background: #37996f;
}
.animate .livestream-cover__progress__inner {
  animation: progress 300s ease-out forwards;
}
.livestream-cover__caption__content {
  overflow: hidden;
  flex: 1;
  font-size: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.livestream-cover__logo {
  margin-left: 10px;
}
.livestream-cover:after {
  content: "";
  display: inline-table;
  padding-top: 53%;
}
.event-livestream-inplay__logo {
  position: absolute;
  top: 12px;
  left: 12px;
  font-size: 0;
}
@media (max-width: 767px) {
  .event-livestream-inplay__list,
  .event-livestream-inplay__logo,
  .event-livestream-inplay__title {
    display: none;
  }
}
.event-livestream-inplay__content {
  display: none;
  flex: 1;
  padding-top: 5px;
}
.event-livestream-inplay__title {
  margin-bottom: 14px;
  font-size: 16px;
  line-height: 1.19;
}
@media (max-width: 767px) {
  .event-livestream-inplay__list,
  .event-livestream-inplay__logo,
  .event-livestream-inplay__title {
    display: none;
  }
}
.event-livestream-inplay__list {
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 1;
  color: #6f8caa;
}
@media (max-width: 767px) {
  .event-livestream-inplay__list,
  .event-livestream-inplay__logo,
  .event-livestream-inplay__title {
    display: none;
  }
}
.event-livestream-inplay__list li {
  position: relative;
  padding-left: 20px;
}
.event-livestream-inplay__list li + li {
  margin-top: 5px;
}
.livestream-steps-simple {
  justify-content: center;
  font-size: 12px;
  line-height: 1.3;
}
.event-livestream-inplay .livestream-steps-simple {
  display: none;
}
@media (max-width: 767px) {
  .event-livestream-inplay .livestream-steps-simple {
    display: flex;
  }
}
.livestream-steps-simple li {
  position: relative;
  padding: 3px 0 0 25px;
}
ol > li:before {
  content: counter(counter);
  counter-increment: counter;
}
.livestream-steps-simple li:before {
  font-family: Montserrat, "Montserrat", sans-serif;
  font-weight: 700;
  font-style: italic;
}
.livestream-steps-simple li:before {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #283e5b;
  color: #6f8caa;
  font-size: 12px;
}
.livestream-steps-simple li + li {
  margin-left: 20px;
}
@media (max-width: 767px) {
  .event-livestream-inplay__actions {
    text-align: center;
  }
}
.btn,
.btn-filter {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  border-radius: 80px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  transition: 0.2s;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
}
.btn {
  padding: 11px 38px;
  border: 2px solid #00b1ff;
  font-size: 12px;
  line-height: 1.1675;
  color: #fff;
  background-color: #00b1ff;
  overflow: hidden;
}
.event-livestream-inplay__actions .btn {
  transition: 0.2s;
}
.event-livestream-inplay__note {
  font-size: 10px;
  line-height: 1.2;
  color: #6f8caa;
  text-align: center;
}
.event-livestream-inplay__content .event-livestream-inplay__note {
  display: none;
}
@media (max-width: 767px) {
  .event-livestream-inplay__content .event-livestream-inplay__note {
    display: block;
    padding-top: 10px;
  }
}
@media (max-width: 767px) {
  .event-livestream-inplay__foot {
    display: none;
  }
}
.livestream-steps {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 13px;
  color: #37996f;
}
.event-livestream-inplay .livestream-steps {
  justify-content: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: #fff;
}
.livestream-step {
  display: flex;
  align-items: center;
}
.livestream-step__indicator {
  font-family: Montserrat, "Montserrat", sans-serif;
  font-weight: 700;
  font-style: italic;
}
.livestream-step__indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 22px;
  height: 22px;
  border-radius: 3px;
  margin-right: 10px;
  background: #37996f;
  font-size: 13px;
  color: #fff;
}
.event-livestream-inplay .livestream-step__indicator {
  width: 50px;
  height: 50px;
  margin-right: 23px;
  font-size: 26px;
}
.livestream-step__content {
  flex: 1;
  white-space: nowrap;
}
.livestream-step + .livestream-step {
  margin-left: 25px;
}
.event-livestream-inplay .livestream-step + .livestream-step {
  margin-left: 33px;
}
.video-container {
  /* padding-top: 56.25%;  สำหรับอัตราส่วน 16:9  */
  /* padding-top: 28px; */
  position: fixed;
  bottom: 0px;
  top: 0px;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  /* height: 170px; */
  height: 100%;
}
/* .is-desktop .video-container {
  height: 115%;
} */
.video-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.livestream-cover__button:hover {
  background-color: rgba(
    0,
    0,
    0,
    0.1
  ); /* คุณสามารถปรับเปลี่ยนสีหรือ style ตามความต้องการ */
}
.fullscreen-button {
  display: block;
  position: absolute;
  bottom: 8px;
  right: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.video-js:hover .fullscreen-button {
  display: block; /* แสดงปุ่มเมื่อ hover บน video-js */
}
.player.iframe {
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  width: 100%;
  height: 100%;
}
/* .vjs-control-bar {
  bottom: -45px !important;
  opacity: 1 !important;
  display: flex !important;
} */
.vjs-matrix.video-js {
  color: #00ff00;
}

/* Change the border of the big play button. */
.vjs-matrix .vjs-big-play-button {
  border-color: #00ff00;
}

/* Change the color of various "bars". */
.vjs-matrix .vjs-volume-level,
.vjs-matrix .vjs-play-progress,
.vjs-matrix .vjs-slider-bar {
  background: #00ff00;
}

/* .vjs-progress-control {
  visibility: hidden;
} */

.video-js .vjs-big-play-button,
.video-js .vjs-icon-placeholder,
.video-js .vjs-icon-placeholder:before {
  display: contents !important;
}
