.match-info {
  padding: 20px 15px;
}
.match-info__inner {
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  margin: 0 -8px -10px;
}
.match-info-item {
  display: flex;
  align-items: center;
  line-height: 1.1;
}
.match-info .match-info-item {
  width: 50%;
  padding: 0 8px 10px;
}
.match-info-item__icon {
  display: flex;
  flex: 36px 0 0;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: #f5f7f9;
  border-radius: 50%;
  margin-right: 8px;
  font-size: 14px;
  color: #6f8caa;
}
.match-info-item__content {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.match-info-item__label {
  display: block;
  margin-bottom: 3px;
  color: #6f8caa;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}
.match-info-item__value {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #1a3150;
  font-weight: 400;
}
.match-info-item__value img {
  max-width: 20px;
  margin-right: 5px;
}
.event-facts {
  padding: 0 15px;
}
.event-facts__title {
  font-family: Montserrat, "Montserrat", sans-serif;
  font-weight: 700;
  font-style: italic;
}
.event-facts__title {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 16px;
  text-align: left;
}
.event-facts__body {
  font-size: 14px;
  line-height: 1.3;
  list-style: none;
}
.event-facts__list {
  margin-bottom: 0;
  list-style: none;
  text-align: left;
}
.event-facts__list > li {
  position: relative;
  padding-left: 20px;
}
.event-facts__list > li:before {
  content: "+";
  position: absolute;
  top: -2px;
  left: 0;
  font-family: Montserrat, "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 700;
  font-style: italic;
  color: #00b1ff;
}
.event-facts__list > li {
  position: relative;
  padding-left: 20px;
}
.event-facts__list > li + li {
  margin-top: 0.65em;
}
.event-facts__list > li:before {
  content: "+";
  position: absolute;
  top: -2px;
  left: 0;
  font-family: Montserrat, "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 700;
  font-style: italic;
  color: #00b1ff;
}
.btn-expand {
  width: 100%;
  height: 40px;
  border-radius: 0;
  background-color: hsla(0, 0%, 100%, 0.87);
  z-index: 6;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
}
.event-facts__actions .btn-expand {
  height: 4.1em;
}
.btn-expand .icon-down {
  display: inline-block;
  margin-left: 3px;
  transition: transform 0.2s ease-in-out;
}
