.content.content--event {
  position: relative;
  margin-bottom: 55px;
  box-shadow: none;
  background-color: #f5f7f9;
}
@media (max-width: 767px) {
  .content.content--event {
    margin-bottom: 0;
  }
}
.content {
  display: flex;
  flex: 1;
  min-width: 0;
  background-color: #fff;
}
@media screen and (max-width: 1023px) {
  .content {
    flex-direction: column;
  }
}
.event-page-wrapper,
.event-wrapper {
  box-sizing: border-box;
  width: 100%;
}
@media (max-width: 1024px) {
  .event-page-wrapper,
  .event-wrapper {
    position: relative;
    z-index: 12;
    min-height: calc(100vh - 115px);
  }
}
.event-header-shadow {
  height: 591px;
  display: none;
}
.event-header-wrapper {
  position: relative;
  z-index: 7;
  background-color: #0f2443;
}
@media (max-width: 767px) {
  .event-header-wrapper {
    z-index: 10;
  }
}
.event-header {
  width: 100%;
  padding: 15px 0 30px;
  background-color: #37996f;
  position: relative;
  z-index: 1;
  color: #fff;
}
@media (max-width: 767px) {
  .event-header {
    padding: 46px 0 25px;
  }
}
.event-header--postmatch {
  padding: 50px 0 30px;
}
@media (max-width: 767px) {
  .event-header--inplay,
  .event-header--postmatch {
    padding-bottom: 0;
  }
}
.event-header--liveStream {
  padding: 0;
}
.is-desktop .event-header--liveStream {
  padding: 0 0 15px 0;
}
.discoverable-bg-img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center;
}
.event-header > img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}
embed,
iframe,
img,
object,
video {
  height: auto;
  max-width: 100%;
}
.is-mobile .event-header-bar {
  justify-content: space-between;
}
.event-header-bar {
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  padding: 0 15px;
  top: 0;
  left: 0;
  width: 100%;
}
@media (max-width: 1023px) {
  .event-header-bar {
    z-index: 6;
    padding: 18px 40px 0;
    justify-content: space-between;
  }
}
.event-header-bar__left-side,
.event-header-bar__right-side {
  position: absolute;
  top: 15px;
  display: flex;
  align-items: center;
  height: 24px;
}
.event-header-bar__left-side {
  left: 10px;
}
@media (max-width: 1023px) {
  .event-header-bar__left-side {
    top: 15px;
  }
}
.event-header-bar .match-favourite-btn {
  padding: 1px 6px;
  margin: 0;
}
/* .match-favourite-btn {
  padding: 10px 10px 10px 15px;
  margin-right: -10px;
} */
button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-family: inherit;
  color: inherit;
  -webkit-appearance: none;
  appearance: none;
}
.event-header-bar .match-favourite-btn__icon {
  color: #fff !important;
}
.match-favourite-btn.is-favourited .match-favourite-btn__icon {
  color: #39ffaa;
}
.match-favourite-btn__icon {
  display: block;
  position: relative;
  font-size: 16px;
  width: 16px;
  height: 16px;
  color: #b6c5d4;
}
.match-favourite-btn.is-favourited .match-favourite-btn__icon:after {
  transform: scale(1.75);
  transition: transform, opacity;
  transition-duration: 0.6s, 0.4s;
  transition-delay: 0s, 0.2s;
  opacity: 0;
}
.match-favourite-btn__icon:after {
  content: " ";
  transform: scale(0);
  transform-origin: 50% 50%;
  transition: none;
  opacity: 1;
  color: #39ffaa;
  position: absolute;
}
.match-favourite-btn__icon:after,
.match-favourite-btn__icon:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
}
.event-header-bar__breadcrumbs {
  flex: 1;
  min-width: 0;
  padding-top: 11px;
  padding-right: 15px;
  font-size: 12px;
}
@media (max-width: 1023px) {
  .event-header-bar__breadcrumbs {
    padding-top: 0;
    margin-right: 0;
  }
}
.event-header-bar__breadcrumbs > ul {
  display: flex;
  align-items: center;
  color: #fff;
}
@media (max-width: 1023px) {
  .event-header-bar__breadcrumbs ul {
    justify-content: center;
  }
}
.event-header-bar__breadcrumbs > ul > li {
  white-space: nowrap;
}
.event-header-bar__breadcrumbs > ul > li > a {
  color: currentColor;
}
.event-header-bar__breadcrumbs > ul > li:first-child:after {
  content: "/";
  display: inline-block;
  margin: 0 6px;
  color: #2ccc88;
}
@media (max-width: 1023px) {
  .event-header-bar__right-side {
    top: 15px;
  }
}
.event-header-bar__right-side {
  right: 10px;
}
.event-dropdown {
  position: relative;
}
@media (max-width: 767px) {
  .event-dropdown__toggle {
    top: 0;
    right: 0;
  }
}
.event-dropdown__toggle {
  display: block;
  width: 24px;
  font-size: 18px;
  color: #fff;
}
@media (max-width: 767px) {
  .event-header--inplay .event-header-matchinfo,
  .event-header--postmatch .event-header-matchinfo {
    padding-bottom: 15px;
  }
}
.event-header-matchinfo {
  position: relative;
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  margin: 0 auto;
  padding: 0 15px;
  max-width: 800px;
}
@media (max-width: 767px) {
  .event-header .event-header-matchinfo__title {
    /* display: none; */
    top: 57px;
    align-items: flex-start;
    font-family: Roboto, "Roboto", sans-serif;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
  }
}
.event-header-matchinfo__title {
  /* display: none; */
  position: absolute;
  z-index: 0;
  top: 30px;
  left: 15px;
  right: 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  font: italic 700 14px/1.154 Montserrat, "Montserrat", sans-serif;
  text-transform: uppercase;
}
.event-header-matchinfo__title__team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 50%;
  min-height: 60px;
  color: inherit !important;
  overflow: hidden;
}
.event-header-matchinfo__title__team--home {
  align-items: flex-end;
  text-align: right;
  padding-right: 226px;
}
@media (max-width: 767px) {
  .event-header .event-header-matchinfo__title__team {
    min-height: auto;
    align-items: center;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .event-header .event-header-matchinfo__title__team--home {
    padding-right: 65px;
  }
}
.event-header-matchinfo__title__team > span {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.sr-only {
  font-size: 0;
  width: 1px;
  height: 1px;
  display: inline-block;
  overflow: hidden;
  position: absolute !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  clip: rect(1px, 1px, 1px, 1px);
}
@media (max-width: 767px) {
  .event-header .event-header-matchinfo__title__team--away {
    padding-left: 65px;
  }
}
.event-header-matchinfo__title__team--away {
  align-items: flex-start;
  padding-left: 226px;
}
.event-header-matchinfo__bar {
  width: 100%;
}
@media (max-width: 767px) {
  .event-header .event-header-team {
    max-width: calc(50% - 70px);
  }
}
@media (max-width: 767px) {
  .event-header .event-header-team--home {
    margin-right: 0;
  }
}
.event-header-team {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  max-width: calc(50% - 145px);
  color: #fff;
}
.event-header-team--home .event-header-team__body {
  justify-content: flex-end;
}
.event-header-team--away .event-header-team__body {
  justify-content: flex-start;
}
@media (max-width: 767px) {
  .event-header .event-header-team__body {
    flex-direction: column;
    position: relative;
  }
}
.event-header-team__body {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 767px) {
  .event-header .event-header-team--home .event-header-team__content {
    order: 2;
    margin-right: 0;
  }
}
.event-header-team--home .event-header-team__content {
  order: 1;
  margin-right: 25px;
  text-align: right;
}
.event-header-team--away .event-header-team__content {
  margin-left: 25px;
  order: 2;
}
@media (max-width: 767px) {
  .event-header .event-header-team--away .event-header-team__content {
    order: 2;
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .event-header .event-header-team__content {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .event-header .event-header-team__name {
    text-transform: none;
  }
}
.event-header-team__name {
  visibility: hidden;
  display: block;
  font-family: "SF Pro", "SF Pro TH", Arial, sans-serif;
  font-size: 14px;
  line-height: 1.154;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 700;
  position: relative;
  color: #fff;
}
@media (max-width: 767px) {
  .event-header-team__name {
    font-family: "SF Pro", "SF Pro TH", Arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
  }
}
.event-header-team__abbr {
  display: none;
  font-size: 14px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .event-header .event-header-team--home .event-header-team__aside {
    order: 1;
  }
}
.event-header-team--home .event-header-team__aside {
  order: 2;
}
.event-header-team__aside {
  position: relative;
}
.event-header-team--away .event-header-team__aside {
  order: 1;
}
@media (max-width: 767px) {
  .event-header .event-header-team--away .event-header-team__aside {
    order: 1;
  }
}
@media (max-width: 767px) {
  .event-header .event-header-team__logo {
    position: static;
    margin-bottom: 7px;
  }
}
.event-header-team__logo {
  position: relative;
}
.event-header-team__logo,
.event-header-team__logo > img {
  display: block;
  width: 60px;
  height: 60px;
}
@media (max-width: 767px) {
  .event-header-team__logo,
  .event-header-team__logo > img {
    height: 50px;
    width: 50px;
  }
}
.event-header-team--home .event-header-team__bar {
  justify-content: flex-end;
  margin-right: -16px;
}
.event-header-team--away .event-header-team__bar {
  margin-left: -16px;
}
@media (max-width: 767px) {
  .event-header-team--away .event-header-team__bar,
  .event-header-team--home .event-header-team__bar {
    justify-content: center;
  }
}
.event-header-team__bar {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
@media (max-width: 767px) {
  .event-header-team__bar {
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .event-header .event-header-team--away,
  .event-header .event-header-team--home .event-header-team__rank {
    margin-left: 0;
  }
}
.event-header-team--home .event-header-team__rank {
  margin-right: 16px;
  margin-left: 22px;
}
.event-header-team__rank {
  width: 60px;
  font-size: 12px;
  color: #39ffaa;
  text-align: center;
}
.event-header-team--away .event-header-team__rank {
  margin-left: 16px;
  margin-right: 22px;
}
@media (max-width: 767px) {
  .event-header .event-header-team--away .event-header-team__rank {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .event-header .event-header-matchinfo__meta {
    flex-basis: 140px;
    padding: 13px 10px 0;
  }
}
.event-header-matchinfo__meta {
  text-align: center;
  position: relative;
  flex: 0 1 290px;
  padding: 6px 20px 0;
}
@media (max-width: 767px) {
  .event-header .event-header-matchinfo__meta__top {
    top: -5px;
  }
}
.event-header-matchinfo__meta__top {
  position: absolute;
  top: -4px;
  left: 0;
  width: 100%;
  text-align: center;
}
.event-fulltime-status,
.event-inplay-status,
.event-time-bottom,
.event-time-top {
  font-family: Montserrat, "Montserrat", sans-serif;
  font-weight: 700;
  font-style: italic;
}
.event-header--inplay .event-inplay-status,
.event-header--inplay .event-time-bottom,
.event-header--inplay .event-time-top {
  color: #f33c59;
}
.event-fulltime-status,
.event-inplay-status,
.event-time-top {
  display: block;
  font-size: 12px;
  line-height: 15px;
  color: #2ccc88;
}
.is-desktop .event-start-date {
  top: 20px;
}
.blinking-apostrophe {
  color: #f33c59;
  opacity: 1 !important;
  animation: blinking-apostrophe 1.2s infinite;
  margin-left: 1px;
}
.event-score-text {
  position: relative;
  display: block;
  font-style: italic;
  font-family: Montserrat, "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #fff;
  min-width: 120px;
  top: 10px;
}
@media (max-width: 767px) {
  .event-score-text {
    font-size: 26px;
    line-height: 1.2;
  }
}
.event-score-text svg {
  display: none;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.event-score-text > span {
  display: inline-block;
}
.event-score-separator {
  margin: 0 6px;
}
.event-score-text > .goal-score-animation-text {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  text-transform: uppercase;
  color: #fff;
  font-family: Montserrat, "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: italic;
}
@media (max-width: 767px) {
  .event-score-text > .goal-score-animation-text {
    top: calc(100% + 7px);
  }
}
.event-header__league,
.event-periods-football {
  font-family: Roboto, "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.event-periods-football,
.event-time-bottom {
  position: relative;
  top: -8px;
  display: block;
  font-size: 12px;
  color: #2ccc88;
}
.event-periods-football {
  display: flex;
  justify-content: center;
}
@media (max-width: 767px) {
  .event-ap-score,
  .event-periods-football,
  .event-time-bottom {
    top: 10px;
  }
}
.event-start-date {
  color: #2ccc88;
  line-height: 1.2;
}
.event-ap-score,
.event-start-date {
  display: block;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  top: 10px;
}
@media (max-width: 767px) {
  .event-start-date {
    font-size: 11px;
  }
}
.event-start-date-small {
  display: none;
  position: relative;
  top: -7px;
  font-size: 11px;
  line-height: 14px;
  color: #2ccc88;
}
.event-starts-in {
  display: block;
  min-height: 28px;
  font: italic 700 24px/1.2 Montserrat, "Montserrat", sans-serif;
  color: #fff;
}
@media (max-width: 767px) {
  .event-starts-in {
    min-height: 26px;
    font-size: 22px;
  }
}
.is-desktop .event-header-matchinfo {
  position: initial;
  align-items: center;
  margin: 40px auto;
}
.is-desktop .event-header-matchinfo__title {
  top: auto;
}
.is-desktop .event-header-matchinfo__meta__top {
  position: initial;
}
.is-desktop .event-periods-football,
.event-time-bottom {
  top: 20px;
}
.is-desktop .event-header-matchinfo__meta {
  flex: 0 1 260px;
}
