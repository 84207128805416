.popup {
  /* box-shadow: 0 8px 6px -6px black; */
  position: absolute;
  /* left: 20%;
  top: 10%; */
  z-index: 9;
  padding-top: 62px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

/* The Close Button */
.close {
  position: absolute;
  /* top: 15px; */
  right: 16px;
  color: #1b1b1b;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.popup-content {
  background-color: #fff; /* สีพื้นหลัง */
  color: #333; /* สีข้อความ */
  padding: 20px; /* ระยะห่างขอบ */
  border-radius: 10px; /* มุมโค้ง */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* เงา */
  max-width: 400px; /* ความกว้างสูงสุด */
  margin: auto; /* จัดกึ่งกลาง */
}

.popup-content h3 {
  color: #ca0700; /* สีข้อความสำหรับ h3 */
  margin-top: 0; /* ไม่มีระยะห่างด้านบน */
  margin-bottom: 10px;
  text-align: center;
  font-size: 1.25em; /* ขนาดข้อความ */
}

.popup-content p {
  font-size: 1em; /* ขนาดข้อความ */
  line-height: 1.5; /* ระยะห่างระหว่างบรรทัด */
  margin-bottom: 10px; /* ระยะห่างด้านล่าง */
}

.button-close {
  background-color: #f44336; /* สีพื้นหลังของปุ่ม */
  color: white; /* สีข้อความ */
  border: none; /* ไม่มีเส้นขอบ */
  padding: 10px 20px; /* ระยะห่างขอบ */
  border-radius: 5px; /* มุมโค้ง */
  cursor: pointer; /* ชี้เป็นปุ่มกด */
  font-weight: bold; /* ข้อความหนา */
}

.button-close:hover {
  background-color: #d32f2f; /* สีพื้นหลังของปุ่มเมื่อโดน hover */
}

.popup-button-container {
  display: flex;
  justify-content: flex-end; /* จัดให้ปุ่มอยู่ทางขวา */
}
