/* AsideContent.css */
.aside {
  min-width: 0;
  border-top: 8px solid #e1e7ed;
  background-color: #fff;
}

@media (min-width: 1024px) {
  .aside {
    flex: 0 0 360px;
    border-top: 0;
    border-left: 1px solid #f5f7f9;
    border-radius: 0 8px 8px 0;
  }
}

@media (min-width: 1280px) {
  .aside {
    flex-basis: 405px;
  }
}

@media (min-width: 1320px) {
  .aside {
    flex-basis: 450px;
  }
}
@media (min-width: 1024px) {
  .aside__content {
    padding: 10px;
  }
}
.match-poll-overview,
.match-poll-overview__placeholder {
  /* min-height: 321px; */
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .wrapper.is-mobile .aside {
    display: none;
  }
}
