.event-header-goal-scorers {
  /* font-family: Roboto,"Roboto",sans-serif; */
  font-weight: 400;
  font-style: normal;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-size: 13px;
  color: #6f8caa;
}
@media (max-width: 767px) {
  .event-header-goal-scorers {
    margin-top: 0;
    font-size: 12px;
    line-height: 1.33;
    padding: 0 15px 15px;
  }
}
.event-header-goal-scorers__items {
  flex: 1;
  margin-bottom: 0;
}
.event-header-goal-scorers__items:first-child {
  text-align: right;
}
.event-header-goal-scorers__icon {
  width: 15px;
  margin: 0 24px;
  text-align: center;
}
@media (max-width: 767px) {
  .event-header-goal-scorers__icon {
    position: relative;
    top: -4px;
    width: 16px;
    font-size: 16px;
  }
}
.event-header-goal-scorers__item {
  margin-bottom: 2px;
}
