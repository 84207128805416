.bottom-bar {
  position: relative;
  z-index: 15;
}
.bottom-bar__nav {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 14;
  width: 100%;
  min-height: 45px;
  padding-bottom: env(safe-area-inset-bottom);
  background: #37996f;
  box-shadow: 0 0 10px 6px rgb(26 80 45 / 20%);
  transition: box-shadow 0.3s;
}
.nav {
  font-size: 10px;
}
.nav-base {
  justify-content: center;
}
.nav i {
  color: inherit;
  margin: 0 auto;
}
.nav-base i {
  margin: 0 auto;
  font-size: 20px !important;
  line-height: 1;
}
.nav-base,
.progressbar-container .progressbar__info {
  display: flex;
  align-items: center;
}
.nav .nav__item {
  position: relative;
  flex: 1;
  height: 45px;
}
.nav .nav__link {
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  color: #fff;
  padding: 5px 14px;
  border: 0;
  background: none;
  font-size: 10px;
  line-height: 1.3;
}
.bottom-bar .nav .nav__link {
  padding: 0;
}
.nav-base .nav__link {
  display: flex;
}
.nav-base .nav__link {
  position: relative;
  text-align: center;
  line-height: 1;
  justify-content: center;
  font-family: inherit;
  font-size: inherit;
}
.nav .nav__link.active,
.nav .nav__link.link-exact-active,
.nav .nav__link:focus,
.nav .nav__link:hover {
  color: #fff;
  background-color: #178556;
}
.nav-base .nav__link.active,
.nav-base .nav__link.link-exact-active,
.nav-base .nav__link:focus,
.nav-base .nav__link:hover {
  text-decoration: none;
}
.bottom-bar__nav .nav-base .nav__link.link-exact-active:before {
  opacity: 1;
}
.bottom-bar .nav .nav__link:before {
  top: -2px;
}
@media (max-width: 767px) {
  .nav-base .nav__link.link-exact-active:before {
    opacity: 0;
  }
}
.nav-base .nav__link.link-exact-active:before {
  opacity: 1;
}
.nav-base .nav__link:before {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 3px;
  border-radius: 3px 3px 0 0;
  background: #007b46;
  opacity: 0;
  transition: 0.3s;
}
.bottom-bar__toggle span {
  position: relative;
  top: -3px;
  width: 20px;
  height: 22px;
}
.bottom-bar__toggle span:before {
  top: 8px;
  box-shadow: 0 6px 0 0 #178556a6;
}
.bottom-bar__toggle span:after {
  top: 20px;
}
.bottom-bar__toggle span:after,
.bottom-bar__toggle span:before {
  content: "";
  position: absolute;
  left: 0;
  width: 20px;
  height: 1px;
  border-radius: 2px;
  background: #6f8caa;
  transition: box-shadow 0.3s, transform 0.3s, background 0.3s;
}
.nav__counter {
  position: absolute;
  z-index: 1;
  top: 2px;
  right: 8px;
  padding: 0 4px;
  font-size: 8px;
  font-weight: 700;
  font-family: Montserrat, "Montserrat", sans-serif;
  font-style: italic;
  color: #fff;
  background-color: #007b46;
  border: 2px solid #fff;
  border-radius: 8px;
}
