/* MatchListHeadline.css */
.match-list-headline {
  align-items: center;
  padding: 3px 10px;
  position: relative;
  background-color: #e1e7ed;
  color: #6f8caa;
  font-size: 13px;
  line-height: 23px;
  font-weight: 500;
  text-transform: uppercase;
  overflow: hidden;
}
.match-list-headline > ul {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.match-list-headline-title {
  display: flex;
}
.p-0 {
  padding: 0 !important;
}
.match-list-headline__icon {
  flex-shrink: 0;
  padding: 7px 10px 7px 15px;
  margin: 0 8px 0 -5px;
  box-shadow: 3px 0 0 0 #fff;
  transform: skew(-20deg);
}
.match-list-headline__icon > img {
  display: block;
  width: 16px;
  height: 16px;
  transform: skew(20deg);
  border-radius: 100px;
}
.match-list-headline li {
  display: inline;
  position: relative;
}
.match-list-headline li > a {
  padding: 5px;
  color: inherit;
}
.match-list-headline li + li:before {
  content: "/";
  opacity: 0.4;
}
.match-list-headline__actions {
  display: flex;
  margin-left: auto;
  font-size: 0;
  white-space: nowrap;
}
.match-list-headline__action {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 46px; */
  height: 30px;
  border-radius: 0;
  font-size: 16px;
  flex-shrink: 0;
  transition: 0.3s;
}
