/* Header.css */
.header {
  /* padding: 0 15px 0 4px; */
  /* display: flex; */
}
@media (max-width: 1199px) {
  .header-desktop {
    padding: 0 0 0 4px;
  }
}
@media (max-width: 1199px) {
  .header__inner {
    width: 100%;
  }
}
.header-desktop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 16;
  width: 100%;
  height: 55px;
  background-color: #007b46;
  font-family: "SF Pro", "SF Pro TH", Arial, sans-serif;
}
@media screen and (max-width: 767px) {
  .header-desktop {
    display: none;
  }
}
.header .logo {
  margin-right: 15px;
}
@media (max-width: 1199px) {
  .header .logo {
    margin-right: 2px;
  }
}
.logo {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  margin-top: 14px;
}
.nev-icon {
  margin: 0 0 0 0 !important;
}
a,
a:hover {
  text-decoration: none;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: 55px;
}

.header-menu {
  display: flex;
  align-items: center;
}

.header-menu-list-item {
  list-style-type: none;
}

.header-menu-list {
  display: flex;
}

.navigation__link {
  position: relative;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  font-size: 13px;
  line-height: 1.1;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
  color: #fff;
}
.navigation__link:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transform: skew(-18deg);
  background: #37996f;
  opacity: 0;
  transition: opacity 0.3s;
}
.header-menu-link.nuxt-link-exact-active:before {
  opacity: 1;
  border-top: 3px solid #00b1ff;
}

.header-menu-link:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transform: skew(-18deg);
  background: #37996f;
  opacity: 0;
  transition: opacity 0.3s;
}

.header-buttons {
  display: flex;
  /* add your styles */
}
.header__wrap {
  width: 100%;
  height: 55px;
}
.header__inner,
.header__wrap {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* max-width: 400px; */
}

.header__inner {
  position: relative;
  z-index: 2;
}

.language-selector {
  position: relative;
  width: 65px;
  height: 28px;
}

.mr-auto {
  margin-right: auto !important;
}

.language-selector__toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 6px;
  border: 0;
  border-radius: 14px;
  background: #ffffff30;
  color: #fff;
  line-height: 28px;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  transition: border-radius 0.3s;
}

.language-selector__toggle .language-selector__label {
  margin-left: 0;
}

.language-selector__label {
  margin-left: 6px;
}

.language-selector__toggle i {
  font-size: 8px;
  opacity: 0.5;
}

.language-selector__dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.language-selector__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 6px;
  border: 0;
  background: #37996f;
  color: #fff;
  text-transform: uppercase;
  text-align: left;
  line-height: 28px;
  cursor: pointer;
}

.header__content,
.header__inner .search__toggle {
  display: flex;
  align-items: center;
}

.navigation {
  margin-right: -10px;
  color: #fff;
}

.navigation__list {
  display: flex;
}

.header-utility-nav--desktop {
  padding-right: 645px;
  margin-right: -600px;
  margin-left: -30px;
}

.header-utility-nav {
  background: #fff;
  margin-left: auto;
  display: flex;
  align-self: stretch;
  align-items: center;
  padding: 0 40px 0 10px;
  transform: skewX(-18deg) translateX(40px);
}

.header-utility-nav__inner {
  display: flex;
  align-items: center;
  transform: skewX(18deg);
}

.header-utility-nav__btn {
  display: block;
  position: relative;
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  border: 0;
  text-align: center;
  border-radius: 50%;
  background-color: #007b46;
  color: #fff !important;
  transition: background-color 0.3s ease-in-out;
}
.header-utility-nav__btn--favorites:after {
  transform: scale(0);
  transform-origin: 50% 50%;
  transition: none;
  opacity: 1;
  color: #fff;
  position: absolute;
}
.header-utility-nav__btn--favorites:after,
.header-utility-nav__btn--favorites:before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 36px;
  height: 36px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-utility-nav__btn--favorites:after,
.header-utility-nav__btn--favorites:before {
  font-family: "oddspedia_font";
  font-style: normal;
  font-weight: 400;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.header-utility-nav__btn--favorites:after,
.header-utility-nav__btn--favorites:before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 36px;
  height: 36px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-dropdown {
  position: relative;
  width: 36px;
  height: 36px;
  flex: 0 0 36px;
}

.header-utility-nav__action {
  margin-left: 10px;
}

.user-dropdown__btn-icon {
  display: block;
  width: 36px;
  height: 36px;
  padding: 0;
  line-height: 36px;
  font-size: 16px;
  border: 0;
  text-align: center;
  border-radius: 50%;
  background-color: #007b46;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.user-dropdown__btn-icon i {
  color: #fff;
  display: inline-block;
}
/* #################### */
.header-mobile {
  transition: 0.1s;
  border-bottom: 1px solid #178556;
  padding: 0 15px 0 4px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 16;
  width: 100%;
  height: 55px;
  background-color: #007b46;
}

/* Using media query for screen sizes */
@media screen and (min-width: 768px) {
  .header-mobile {
    display: none;
  }
}
@media (max-width: 767px) {
  .wrapper.is-mobile {
    padding-bottom: calc(45px + env(safe-area-inset-bottom));
  }
}
@media (max-width: 1280px) {
  .wrapper.is-mobile {
    padding-top: 55px;
    padding-bottom: calc(45px + env(safe-area-inset-bottom));
  }
}
.is-mobile .header-mobile {
  top: 0;
  height: 55px;
  transition: 0.1s;
}
.nav-mobile__toggle span {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateX(3px);
}
.nav-mobile__toggle span:before {
  top: 11px;
  box-shadow: 0 8px 0 0 #fff;
}
.nav-mobile__toggle span:after {
  width: 16px;
  top: 27px;
}
.nav-mobile__toggle span:after,
.nav-mobile__toggle span:before {
  content: "";
  position: absolute;
  left: 9px;
  width: 22px;
  height: 2px;
  border-radius: 2px;
  background: #fff;
  transition: box-shadow 0.3s, transform 0.3s, background 0.3s;
}
.nav-mobile__toggle {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  padding: 0;
  border: 0;
  background: none;
}
.nav-mobile.active {
  transform: translateX(0);
  box-shadow: 0 0 10px 5px #178556a6;
}

.nav-mobile__toggle:after {
  content: "";
  position: fixed;
  top: 55px;
  bottom: 0;
  right: 0;
  left: 417px;
  background: #178556a6;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}
@media (max-width: 767px) {
  .nav-mobile__toggle:after {
    left: 320px;
  }
}
.nav-mobile {
  position: fixed;
  top: 55px;
  left: 0;
  z-index: 999;
  overflow: hidden;
  width: 417px;
  height: calc(100% - 55px);
  box-shadow: 0 0 10px 5px rgba(26, 49, 80, 0);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s;
  background: #178556;
}
@media (max-width: 767px) {
  .nav-mobile {
    width: 320px;
  }
}
.nav-mobile__inner {
  position: relative;
  height: 100%;
  width: 300%;
  display: flex;
  transition: 0.3s;
}
.nav-mobile__main {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 33.33%;
  padding-bottom: 20px;
}
@media (max-width: 767px) {
  .navigation-list-links-item {
    border-bottom: 1px solid #37996f;
  }
}
.nav-mobile__dropdowns,
.nav-mobile__dropdowns-lvl-2 {
  position: relative;
  width: 33.33%;
}
@media (max-width: 767px) {
  .header-mobile .logo {
    /* width: 148px; */
    flex: 0 0 148px;
    padding-bottom: 4px;
    margin-right: 0;
    margin-left: 5px;
  }
}
@media (max-width: 1199px) {
  .header-mobile .logo {
    margin-right: 0;
  }
}
.header-mobile .logo > svg {
  max-width: 100%;
}
@media (max-width: 767px) {
  .header-utility-nav {
    transform: skewX(-18deg);
    padding-right: 20px;
    margin-right: -25px;
    background: #37996f;
    border-left: 10px solid #178556;
    margin-bottom: 8px;
  }
}
@media (max-width: 767px) {
  .header-utility-nav__inner {
    flex-flow: row-reverse;
  }
}
.header-utility-nav__inner {
  display: flex;
  align-items: center;
  transform: skewX(18deg);
  padding-top: 4px;
}
.search {
  position: relative;
}
@media (max-width: 767px) {
  .header-utility-nav__action {
    margin-left: 6px;
  }
}
.header-utility-nav__action {
  margin-left: 10px;
}
.header__content,
.header__inner .search__toggle {
  display: flex;
  align-items: center;
}
.header__inner .search__toggle {
  justify-content: center;
}
.mobile__icon {
  margin: 0 0 0 0 !important;
}
.search__icon {
  top: -10px;
  right: -2px;
}
.search__toggle {
  display: block;
  width: 36px;
  height: 36px;
  padding: 0;
  border: 0;
  font-size: 16px;
  line-height: 36px;
  text-align: center;
  border-radius: 50%;
  background-color: #007b46;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}
.search__toggle i {
  display: inline-block;
}
@media (max-width: 767px) {
  .header-utility-nav__action {
    margin-left: 6px;
  }
}
.header-utility-nav__action {
  margin-left: 10px;
}
@media (max-width: 767px) {
  .menu {
    width: 100%;
  }
}
.menu {
  position: fixed;
  top: 55px;
  right: 0;
  z-index: 20;
  width: 484px;
  height: calc(100% - 100px);
  background: #e1e7ed;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}
.nav-mobile__toggle span:after,
.nav-mobile__toggle span:before {
  content: "";
  position: absolute;
  left: 9px;
  width: 22px;
  height: 2px;
  border-radius: 2px;
  background: #fff;
  transition: box-shadow 0.3s, transform 0.3s, background 0.3s;
}
.nav-mobile__toggle span:after {
  width: 16px;
  top: 27px;
}
.nav-mobile__toggle.active span:before {
  transform: rotate(45deg) translate(3px, 8px);
}
.nav-mobile__toggle.active span:after {
  width: 22px;
  transform: rotate(-45deg) translate(3px, -8px);
}
.nav-mobile__toggle.active span:after,
.nav-mobile__toggle.active span:before {
  background: #fff;
  box-shadow: 0 0 0 0 rgba(0, 177, 255, 0);
}
.nav-mobile__toggle.active:after {
  transform: translateX(0);
}
.navigation-link {
  position: relative;
  display: flex;
  width: 100%;
  height: 36px;
  align-items: center;
  color: #fff;
}
@media (max-width: 767px) {
  .navigation-link {
    height: 50px;
    padding: 0 10px;
  }
}
.navigation-link__image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 36px;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  margin-right: 10px;
  background: #37996f;
  font-size: 20px;
  color: #fff;
}
.navigation-link--primary .navigation-link__text_menu {
  /* font-family: Montserrat,"Montserrat",sans-serif; */
  font-weight: 700;
  font-style: italic;
}
.navigation-link__text_menu {
  font-size: 14px;
  line-height: 1.1;
}
.navigation-link:after {
  content: "";
  position: absolute;
  top: calc(50% - 5px);
  right: 15px;
  display: inline-block;
  width: 6px;
  height: 10px;
  border-bottom: 2px solid #00b1ff;
  border-right: 2px solid #00b1ff;
  transform: rotate(45deg);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}
@media (max-width: 767px) {
  .navigation-list-links-item + .navigation-list-links-item {
    margin-top: 0;
  }
}
.navigation-list-links-item + .navigation-list-links-item {
  margin-top: 5px;
}
