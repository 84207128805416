.event-section {
  background-color: #fff;
  border-bottom: 8px solid #e1e7ed;
}
.event-section__head {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  padding: 0 15px;
}
.event-section--h2h .event-section__head,
.event-section--lineup .event-section__head {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
}
.event-h2h:last-child {
  margin-bottom: 0;
}
.aside .event-h2h {
  padding: 0;
}
.event-section__title {
  font-family: Montserrat, "Montserrat", sans-serif;
  font-weight: 700;
  font-style: italic;
}
.event-section__title {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 14px;
}
.event-section__meta {
  font-size: 11px;
  color: #6f8caa;
}
.h2h-graph {
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 20px;
}
.h2h-graph,
.h2h-graph__team {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .h2h-graph {
    padding: 0 20px;
  }
}
@media (min-width: 768px) {
  .h2h-graph,
  .h2h-results-primary {
    max-width: 720px;
    margin: 0 auto;
  }
}
.aside .h2h-graph {
  padding: 0 10px;
}
.h2h-graph__team {
  flex-direction: column;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  color: #162b46;
}
@media (min-width: 375px) {
  .h2h-graph__team:first-child {
    margin-right: 20px;
  }
}
@media (min-width: 375px) {
  .h2h-graph__team:last-child {
    margin-left: 20px;
  }
}
.h2h-graph__team img {
  width: 40px;
  height: 40px;
  margin-bottom: 4px;
}
.h2h-graph__team span {
  font-family: Roboto, "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.h2h-graph__team span {
  font-size: 13px;
  text-transform: uppercase;
}
.h2h-graph__stats-wrapper {
  flex-grow: 1;
}
.h2h-graph__stats {
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.h2h-graph__score {
  position: relative;
  padding: 0 16px 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.h2h-graph__score strong {
  font-family: Montserrat, "Montserrat", sans-serif;
  font-weight: 700;
  font-style: italic;
}
.h2h-graph__score strong {
  font-size: 28px;
  line-height: 1;
}
.aside .h2h-graph__score strong {
  font-size: 22px;
}
.h2h-graph__score span {
  color: #6f8caa;
  font-size: 11px;
  text-transform: uppercase;
}
.h2h-graph__score + .h2h-graph__score:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  bottom: 11px;
  right: 0;
  z-index: 0;
  width: 1px;
  background: #e1e7ed;
  transform: rotate(10deg);
}
.h2h-graph__progressbar {
  display: flex;
  flex-direction: row;
}
.progressbar-container {
  display: flex;
  flex-wrap: wrap;
  border-radius: 8px;
  padding: 12px;
  text-align: center;
}
.h2h-graph__progressbar .progressbar-container {
  padding: 4px;
  width: 50%;
}
.progressbar-small {
  position: relative;
  display: block;
  width: 100%;
  background: #e1e7ed;
  border-radius: 6px;
  overflow: hidden;
  height: 6px;
}
.h2h-graph__progressbar .progressbar-small {
  height: 10px;
}
.aside .h2h-graph__progressbar .progressbar-small {
  height: 6px;
}
.progressbar-small div {
  background: #00b1ff;
  position: absolute;
  top: 0;
  left: auto;
  bottom: 0;
  right: 0;
  z-index: 0;
  transform: skew(-30deg);
  width: 46.1538%;
}
.progressbar--left div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: auto;
  z-index: 0;
  transform: skew(150deg);
  width: 23.0769%;
}
.progressbar-container + .progressbar-container .progressbar-small div {
  background: #1a3150;
}
.h2h-graph__total {
  text-align: center;
  color: #6f8caa;
  font-size: 11px;
  text-transform: uppercase;
  margin-top: 5px;
}
@media (min-width: 768px) {
  .h2h-graph,
  .h2h-results-primary {
    max-width: 720px;
    margin: 0 auto;
  }
}
.aside .h2h-results-primary {
  margin: 0 -10px;
}
.h2h-results-primary__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f5f7f9;
  cursor: pointer;
  transition: background 0.3s;
  color: inherit;
}
.aside .h2h-results-primary__item {
  padding: 5px 0;
}
.h2h-results-primary__match-date,
.h2h-results-primary__team {
  font-family: Roboto, "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.h2h-results-primary__match-date {
  font-size: 11px;
  text-transform: uppercase;
  color: #6f8caa;
}
.h2h-results-primary__item > div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.h2h-results-primary__team {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  font-size: 13px;
  line-height: 1.3;
  color: #6f8caa;
}
.h2h-results-primary__team:first-child {
  text-align: right;
}
@media (max-width: 767px) {
  .h2h-results-primary__team {
    color: #6f8caa;
  }
}
.event-h2h .is-winning {
  font-weight: 500;
  color: #1a3150;
}
.h2h-results-primary__score-desktop {
  font-family: Roboto, "Roboto", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.h2h-results-primary__score-desktop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  color: #1a3150;
}
.h2h-results-primary__score-desktop,
.h2h-results-primary__score-mobile {
  margin: 6px 30px;
  border-radius: 4px;
  font-size: 14px;
  background-color: #e1e7ed;
}
@media (max-width: 767px) {
  .h2h-results-primary__score-desktop {
    display: none;
  }
}
.h2h-results-primary__score-mobile {
  display: none;
  flex-direction: row;
  color: #6f8caa;
  text-align: center;
}
@media (max-width: 767px) {
  .h2h-results-primary__score-mobile {
    display: flex;
  }
}
.h2h-results-primary__score-mobile > div {
  padding: 2px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.h2h-results-primary__score-mobile > div:first-child {
  border-right: 1px solid #fff;
}
.btn-expand {
  width: 100%;
  height: 40px;
  border-radius: 0;
  background-color: hsla(0, 0%, 100%, 0.87);
  z-index: 6;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
}
.color-primary {
  color: #00b1ff !important;
}
.h2h-results-pagination {
  height: 36px;
  border-top: 1px solid #f5f7f9;
}
.btn-expand .icon-down {
  display: inline-block;
  margin-left: 3px;
  transition: transform 0.2s ease-in-out;
}
