.match-list-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 57px;
  width: 100%;
  /* padding: 0 5px 0 0px; */
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  background-color: #fff;
}
.match-list-item--inplay {
  box-shadow: inset 3px 0 0 0 #f33c59;
}
.match-list-item:not(:last-child) {
  border-bottom: 1px solid #f5f7f9;
}
.match {
  width: 100%;
  display: flex;
  align-items: center;
}
.match-list-item .match-teams {
  margin-right: auto;
}
.match-url--flex {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
}
.match-state {
  max-width: 41px;
  flex: 0 0 41px;
  text-align: center;
  font-size: 12px;
  color: #37996f;
}
.match-status {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.match-status--inplay,
.match-status--special {
  color: #f33c59;
}
.match-state-outer {
  position: relative;
}
.match-team__name {
  font-size: 13px;
  line-height: 22px;
  color: #37996f;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.image-size-sm {
  height: 20px !important;
  width: 20px !important;
}
.match-team__logo {
  margin: 0 4px;
  vertical-align: top;
  font-size: 0;
}
.match-sets {
  display: grid;
  grid-template-columns: repeat(5, 22px);
}
.match-sets--inplay .match-set--current {
  color: #f33c59;
}
.match-set,
.match-set .match-score-result {
  text-align: center;
}
.match-score {
  display: grid;
  grid-template-columns: 22px;
  grid-template-rows: repeat(2, 20px);
  grid-row-gap: 1px;
  color: #37996f;
}
.match-score--end {
  font-weight: 800;
}
.muted {
  opacity: 0.6;
}
.match-score__team {
  color: #37996f;
  position: relative;
  grid-column: 1/3;
  padding-right: 10px;
}
.match-score--inplay {
  color: #f33c59;
  font-weight: 700;
}
.match-score-result {
  display: block;
  height: 100%;
  width: 100%;
  padding: 0 4px;
  text-align: right;
  position: relative;
  border-radius: 4px;
  background-color: transparent;
}
.match-set,
.match-set .match-score-result {
  text-align: center;
}
.match-game-score {
  display: grid;
  grid-template-columns: 36px;
  grid-template-rows: repeat(2, 20px);
  grid-row-gap: 1px;
  color: #37996f;
}
.match-game-score__team {
  display: grid;
  grid-template-columns: 14px 22px;
  grid-template-rows: 100%;
  height: 100%;
}
.match-game-score__team--home {
  grid-row: 1/2;
}
.match-game-score__team--away {
  grid-row: 2/3;
}
.match-game-score--inplay .match-game-score__team {
  color: #f33c59;
}
.match-game-score__team .match-score-result {
  grid-column: 2/3;
}
.match-game-score__team .serving-icon {
  grid-column: 1/2;
  margin: auto;
}
.match-favourite-btn {
  display: none;
  padding: 10px 10px 10px 10px;
  /* padding-left: 5px; */
  /* margin-right: -5px; */
}
.match-favourite-btn__icon {
  display: block;
  position: relative;
  font-size: 16px;
  width: 16px;
  height: 16px;
  color: #b6c5d4;
}
.match-favourite-btn__icon:after {
  transform: scale(0);
  transform-origin: 50% 50%;
  transition: none;
  opacity: 1;
  color: #00b1ff;
  position: absolute;
}
.match-favourite-btn__icon:after,
.match-favourite-btn__icon:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
}
.match-live {
  color: red;
}
.match-comming {
  color: grey;
}
.match-lineup {
  color: orange;
}
.icon-setting {
  display: flow;
  width: 20px;
}
.match-team {
  display: flex;
  align-items: center;
}
.match-teams--highlight-winner .match-team {
  opacity: 0.8;
}
.match-teams--highlight-winner .match-team--winner {
  opacity: 1;
  font-weight: 800;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.match-list-item.highlight {
  background-color: #37996f;
  animation: blink 1s infinite;
}
.match-team__name.highlight {
  color: #fff;
}
.match-score__team.highlight {
  color: #fff;
}
.match-status--inplay.highlight {
  color: #fff;
}

.match-list-item .event-red-card-indicator {
  display: inline-block;
  vertical-align: middle;
  margin-left: 3px;
}
.event-red-card-indicator__icon {
  display: block;
  width: 10px;
  height: 12px;
  background-image: url("../../../assets/images/red-card.svg");
  background-size: 100% 100%;
  text-align: center;
  line-height: 12px;
  font-size: 9px;
  color: #fff;
  font-weight: 700;
}
.match-list-item .event-red-card-indicator__icon {
  width: 6px;
  height: 7px;
}
.match-score__team.match-score--not-started {
  display: none;
}
.match-set.match-set--current-HalfTime {
  color: #999;
}
.is-mobile .match-set--current-HalfTime {
  display: none;
}
.match-live-lineup::after {
  background: #e1e7ed;
  content: "";
  height: calc(100% - 12px);
  position: absolute;
  right: 66px;
  top: 6px;
  width: 1px;
}
.match-live-or-lineup {
  padding: 0px 10px 0px 10px;
}
.match-live-lineup {
  display: flex;
  padding-left: 12px;
}
.is-mobile .match-live-lineup {
  display: flex;
  padding-left: 0;
}
.match-live-lineup svg {
  max-width: 16px;
  max-height: 16px;
}
.show-match-stats:hover .match-stats {
  display: block;
}
.match-stats {
  display: none;
  transform: translateY(10px);
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -o-transform: translateY(10px);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  width: 300px;
  left: 18%;
  background-color: #fff;
  z-index: 2;
}
.show-match-stats {
  display: contents;
}
